import React, { useEffect, useState } from "react";
import axios from "axios";
import Url from "../Element/Api";
import useAuth from "../../hooks/useAuth";
import Sliders from "../../assets/s3.svg";
import { ClipLoader } from "react-spinners";
import { useParams } from "react-router-dom";
import Closer from "../../assets/close-grey.svg";

const RealtordownOptions = [
  { type: "heading", content: "Payment" },
  { type: "option", content: "All", value: " " },
  { type: "option", content: "Successful", value: "succeeded" },
  { type: "option", content: "Failed", value: "failed" },
];

const TransactionModal = ({ currentPage, onSetCurrentPage, totalPages, handleSelect, selectedOptionRealtor, onSetTotalPages }) => {
  const { id } = useParams();
  const [config] = useAuth();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [transactions, setTransactions] = useState([]);
  const [transactionLoading, setTransactionLoading] = useState(true);

  const RealtorDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const nextPage = () => {
    onSetCurrentPage((prev) => Math.min(prev + 1, totalPages));
  };

  const prevPage = () => {
    onSetCurrentPage((prev) => Math.max(prev - 1, 1));
  };

  const handleOptionSelect = (option) => {
    handleSelect(option);
    setIsDropdownOpen(false);
  };

  useEffect(() => {
    const fetchTransactions = async () => {
      try {
        setTransactionLoading(true);
        const queryValue = RealtordownOptions.find((item) => item.content === selectedOptionRealtor)?.value || "";
        const response = await axios.get(Url.BASEURL + `/admin/get-transactions-of-broker/${id}?page=${currentPage}&status=${queryValue}`, config);
        if (response.data.success) {
          setTransactions(response.data.allTransactions);
          onSetCurrentPage(response.data.currentPage);
          onSetTotalPages(response.data.totalPages);
        } else {
          setTransactionLoading(false);
        }
      } catch (error) {
        setTransactionLoading(false);
      } finally {
        setTransactionLoading(false);
      }
    };

    const timer = setTimeout(() => {
      fetchTransactions();
    }, 1000);

    return () => clearTimeout(timer);
  }, [currentPage, selectedOptionRealtor]);

  return (
    <div className="modal fade realtor_modal all_transactionsal" id="addRealtorTransactionModal" tabIndex="-1" aria-labelledby="addRealtorTransactionModalLabel" aria-hidden="true">
      <div className="modal-dialog modal-lg">
        <div className="modal-content">
          <div className="modal-body custo_real-modal">
            <div className="modal_closer" data-bs-dismiss="modal" aria-label="Close">
              <img src={Closer} alt="" />
            </div>

            <div className="full_rel_trans d-flex">
              <div className="left_real_heading">Broker Transaction</div>
              <div className="right_real_dropdown">
                <div className="filter_area">
                  <div className="agent_dropdown">
                    <div className={`drop_data ${isDropdownOpen ? "open" : ""}`}>
                      <div
                        className="selected_option"
                        onClick={RealtorDropdown}
                        style={{
                          backgroundImage: `url(${Sliders})`,
                        }}
                      >
                        <label>Filters</label>
                      </div>
                      {isDropdownOpen && (
                        <div className="drop_selection">
                          {RealtordownOptions.map((item, index) => {
                            if (item.type === "heading") {
                              return <h3 key={index}>{item.content}</h3>;
                            } else {
                              return (
                                <p key={index} onClick={() => handleOptionSelect(item.content)}>
                                  {item.content}
                                </p>
                              );
                            }
                          })}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="payment_type_status all_realtor_payment_transactions">
              <div className="table-responsive">
                {transactionLoading ? (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "50vh",
                    }}
                  >
                    <ClipLoader color="#80C342" loading={transactionLoading} size={150} aria-label="Loading Spinner" data-testid="loader" />
                  </div>
                ) : (
                  <table>
                    <thead>
                      <tr>
                        <th>Payment Type</th>
                        <th>Realtor</th>
                        <th>Broker</th>
                        <th>Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {transactions.length === 0 ? (
                        <tr className="text-center">
                          <td
                            colSpan="9"
                            style={{
                              fontSize: 16,
                              marginTop: "10px",
                              alignSelf: "center",
                              textAlign: "center",
                            }}
                          >
                            No Transactions Found
                          </td>
                        </tr>
                      ) : (
                        transactions.map((transaction, index) => (
                          <tr key={index}>
                            <td
                            // dangerouslySetInnerHTML={{
                            //   __html: transaction.detail,
                            // }}
                            >
                              MLS <span className="blacking">{transaction.selectedMlsCount}</span> :<span className="blacking"> ${transaction.selectedMlsPrice}</span> + Wovnn Idx Fees : <span className="blacking">${transaction.idxMlsfee}</span>
                              <br />
                           
                            
                                <div>
                                  Realtor Seats :{" "}
                                  <span className="blacking">
                                    {transaction.totalRealtorcountunderBroker} : ({transaction.totalRealtorpriceunderBroker})
                                  </span>
                                </div>
                            
                             
                            </td>
                            <td>
                              {transaction.realtorName ? (
                                <>
                                  <div className="blacking">
                                    <span className="blacking">${transaction.amount}</span>
                                  </div>
                                  <div className="blacking">{transaction.realtorName}</div>
                                </>
                              ) : (
                                <div className="blacking">-</div>
                              )}
                            </td>

                            <td>
                              <div className="blacking">
                                <span className="blacking">${transaction.brokerFinalAmount}</span>
                              </div>
                              <div className="blacking">{transaction.brokerName}</div>
                            </td>

                            <td>
                              {/* <div className="status">${transaction.wovnnCut}</div> */}
                              <div className="status">${transaction.amount}</div>
                              <div className="success super_class">{transaction.status === "succeeded" ? "Successful" : "Failed"}: </div>
                              <div className="date_set super_class">
                                {new Date(transaction.date).toLocaleDateString("en-US", {
                                  year: "numeric",
                                  month: "2-digit",
                                  day: "2-digit",
                                })}
                              </div>
                            </td>
                          </tr>
                        ))
                      )}
                    </tbody>
                  </table>
                )}
              </div>
              {totalPages > 1 && (
                <div className="custom-pagination">
                  <ul>
                    <li className="left_page" onClick={prevPage}>
                      Prev
                    </li>
                    {Array.from({ length: totalPages }, (_, i) => i + 1).map((number) => (
                      <li key={number} onClick={() => onSetCurrentPage(number)} className={currentPage === number ? "active-page" : ""}>
                        {number}
                      </li>
                    ))}
                    <li className="right_page" onClick={nextPage}>
                      Next
                    </li>
                  </ul>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TransactionModal;
