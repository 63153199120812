import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import "./Transactions.css";
import Url from "../Element/Api";
import Sliders from "../../assets/s3.svg";
import useAuth from "../../hooks/useAuth";
import ReactPaginate from "react-paginate";
import TransactionsData from "./TransactionsData";
import BaseLayout from "../../layouts/BaseLayout";
import ClipLoader from "react-spinners/ClipLoader";
import { NextLabel, PreviousLabel, handleDropdownClose } from "../../utils/utils";

const AgentdropdownOptions = [
  { type: "option", content: "All", value: " " },
  { type: "option", content: "Successful", value: "succeeded" },
  { type: "option", content: "Failed", value: "failed" },
];

function Transactions() {
  const [config] = useAuth();
  const dropdownRef = useRef(null);
  const [loading, setLoading] = useState(true);
  const [totalPage, setTotalPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalResults, setTotalResults] = useState(0);
  const [transactions, setTransactions] = useState([]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [totalTransactions, setTotalTransactions] = useState(0);
  const [selectedOption, setSelectedOption] = useState("Filters");

  // Pagination
  const pageNumbers = [];
  const itemsPerPage = 12;
  const totalPages = Math.ceil(totalResults / itemsPerPage);
  const indexOfLastItem = Math.min(currentPage * itemsPerPage, totalTransactions);
  const indexOfFirstItem = Math.min((currentPage - 1) * itemsPerPage + 1, totalTransactions);

  for (let i = 1; i <= totalPages; i++) {
    pageNumbers.push(i);
  }

  const PostsDropdown = () => {
    if (!isDropdownOpen) {
      setIsDropdownOpen(true);
    }
  };

  useEffect(() => {
    const handleClose = () => {
      setIsDropdownOpen(false);
    };
    handleDropdownClose(dropdownRef, handleClose);
  }, [dropdownRef]);

  const handleOptionSelect = (option) => {
    setSelectedOption(option === " " ? "All" : option);
    setIsDropdownOpen(false);
    setCurrentPage(1)
  };

  const handlesearchtermChange=(e)=>{
     setSearchTerm(e.target.value);
       setCurrentPage(1);
  }
  // To get all the transactions of broker & realtor under admin
  const fetchTransactions = () => {
    setLoading(true);
    let url = Url.BASEURL + `/admin/get-transactions?page=${currentPage}&searchTerm=${searchTerm}`;
    if (selectedOption !== "All") {
      const queryValue = AgentdropdownOptions.find((option) => option.content === selectedOption)?.value || "";
      url += `&status=${queryValue}`;
    }

    axios
      .get(url, config)
      .then((response) => {
        setTransactions(response.data.allTransactions || []);
        setTotalPage(response.data.totalPages);
        setTotalTransactions(response.data.totalTransactions);
        setLoading(false);
        setTotalResults(response.data.totalTransactions);
      })
      .catch((error) => {
        console.error("Error fetching transactions:", error);
      });
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      fetchTransactions();
    }, 500);

    return () => clearTimeout(timer);
  }, [searchTerm, selectedOption, currentPage]);

  const handlePageClick = (e) => {
    const selectedPage = e.selected + 1;
    setCurrentPage(selectedPage);
  };

  const breadcrumbData = [{ label: "Transactions" }];

  return (
    <BaseLayout addClass breadcrumbData={breadcrumbData}>
      <div className="mutual_blocks amp-pages shrimp">
        <h2>Transactions</h2>

        <div className="posting_area">
          <div className="row  padding ">
            <div className="col-md-6 col-sm-12 d-flex justify-content-start align-items-center link_sett">
              <div className="results_realtor transactions_listing pls_listing ctaali">{`${indexOfFirstItem}-${indexOfLastItem} of ${totalTransactions} Results`}</div>
            </div>
            <div className="col-md-6 col-sm-12 d-flex justify-content-end mku">
              <div className="search_area d-flex">
                <div className="search_bloxk">
                  <input
                    type="search"
                    className="form-control me-2"
                    placeholder="Search by Broker Name, Realtor Name, MLS"
                    value={searchTerm}
                    onChange={handlesearchtermChange}
                  />
                </div>
              </div>

              <div className="filter_area">
                <div className="agent_dropdown">
                  <div ref={dropdownRef} className={`drop_data ${isDropdownOpen ? "open" : ""}`}>
                    <div className="selected_option" onClick={PostsDropdown} style={{ backgroundImage: `url(${Sliders})` }}>
                      {selectedOption}
                    </div>
                    {isDropdownOpen && (
                      <div className="drop_selection">
                        {AgentdropdownOptions.map((option, index) => (
                          <p key={index} onClick={() => handleOptionSelect(option.content)}>
                            {option.content}
                          </p>
                        ))}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Table */}
          <div className="payment_type_status main_transaction_page">
            {loading ? (
              <div className="text-center py-5">
                <ClipLoader color="#80C342" loading={loading} size={150} aria-label="Loading Spinner" data-testid="loader" />
              </div>
            ) : (
              <div className="table-responsive">
                <table>
                  <thead>
                    <tr>
                      <th>Payment Type</th>
                      <th className="alignment">Realtor</th>
                      <th className="alignment">Broker</th>
                      <th className="alignment_right">Status</th>
                    </tr>
                  </thead>

                  <tbody>
                    {transactions.length === 0 ? (
                      <tr className="text-center">
                        <td
                          colSpan="9"
                          style={{
                            fontSize: 16,
                            marginTop: "10px",
                            alignSelf: "center",
                            textAlign: "center",
                          }}
                        >
                          No Transactions Found
                        </td>
                      </tr>
                    ) : (
                      transactions?.map((allTransactions, index) => <TransactionsData key={index} allTransactions={allTransactions} />)
                    )}
                  </tbody>
                </table>
              </div>
            )}
            {totalPages > 1 && (
              <div className="all_pagination">
                <ReactPaginate previousLabel={<PreviousLabel />} nextLabel={<NextLabel />} breakLabel={"..."} pageCount={totalPages} marginPagesDisplayed={1} pageRangeDisplayed={5} onPageChange={handlePageClick} containerClassName={"pagination"} activeClassName={"active"} />
              </div>
            )}
          </div>
        </div>
      </div>
    </BaseLayout>
  );
}

export default Transactions;
