import React, { useState } from "react";
import "./Sidebar.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { NavLink } from "react-router-dom";
import { faBars, faTimes } from "@fortawesome/free-solid-svg-icons";
import DashboardLogo from "../../../assets/logo.svg";
import DashboardLogoSmall from "../../../assets/ap.png";
import ArrowRight from "../../../assets/left-arrow.png";

const Sidebar = () => {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [isSidebarVisible, setIsSidebarVisible] = useState(false);

  const options = [
    { name: "Dashboard", iconClass: "icon-dashboard", link: "/dashboard" },
    { name: "Manage Broker", iconClass: "icon-location", link: "/manage-broker" },
    { name: "Listing", iconClass: "icon-list-format", link: "/listings" },
    { name: "Set Payments", iconClass: "icon-dollar", link: "/set-payments" },
    // { name: "Packages", iconClass: "icon-packages", link: "/packages" },
    { name: "Transactions", iconClass: "icon-document1", link: "/transactions" },
    { name: "Support", iconClass: "icon-help", link: "/support" },
  ];

  const toggleSidebar = () => {
    setIsSidebarVisible(!isSidebarVisible);
  };

  return (
    <>
      <button className="toggle-button" onClick={toggleSidebar}>
        <FontAwesomeIcon icon={isSidebarVisible ? faTimes : faBars} />
      </button>
      <div className={`sidebar ${isCollapsed ? "collapsed" : ""} ${isSidebarVisible ? "visible" : ""}`}>
        <div className="logo main-logo">
          <NavLink to="/dashboard">
            <img src={isCollapsed ? DashboardLogoSmall : DashboardLogo} alt="Logo" className={isCollapsed ? "logo-image-small" : "logo-image"} />
          </NavLink>
        </div>
        <ul className="options">
          <div className={`middle_sidebar ${isCollapsed ? "collapsed" : ""}`}>
            {options.map((option, index) => (
              <li
                key={index}
                className={`option ${option.hasBottomBorder ? "border-bottom-class" : ""}
                  ${option.specialClass ? "special-class" : ""}
                  ${option.setting_only ? "setting_only" : ""}
                  ${option.blog_only ? "blog_only" : ""}`}
              >
                <NavLink to={option.link} className={`option-link ${isCollapsed ? "collapsed" : ""}`} activeClassName="active">
                  <span className={`option-icon ${option.iconClass}`}></span>
                  {!isCollapsed && option.name}
                </NavLink>
              </li>
            ))}
          </div>
        </ul>
        <button className={`collapse-btn btn-custom ${isCollapsed ? "collapsed" : ""}`} onClick={() => setIsCollapsed(!isCollapsed)}>
          <img src={ArrowRight} alt="Collapse Icon" className="arrow-icon" />
          {!isCollapsed && " Collapse"}
        </button>
      </div>
    </>
  );
};

export default Sidebar;
