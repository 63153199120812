import "./BrokerLogin.css";
import Logo from "../../../assets/logo.png";
import Edit from "../../../assets/pencil.svg";

import React, { useState, useEffect } from "react";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";

function BrokerLogin() {
  const [imagePreview, setImagePreview] = useState(
    "https://via.placeholder.com/150"
  );

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };
  /* Range-slider */
  const [value, setValue] = useState(10);

  const onSliderChange = (newValue) => {
    setValue(newValue);
  };

  const onInputChange = (event) => {
    setValue(event.target.value);
  };

  /* Range-slider */

  /* hide show */
  const [agentType, setAgentType] = useState("independent");

  return (
    <div className="login-register">
      <div className="login-header">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-6 col-sm-6">
              <div className="logo">
                {" "}
                <img src={Logo} alt="Logo" />
              </div>
            </div>
            <div className="col-lg-6 col-sm-6">
              <div className="mobile">(415) 144 0781</div>
            </div>
          </div>
        </div>
      </div>

      <div className="container container_bg custom_principal_page">
        <div className="row ">
          <div className="col-lg-12">
            <div className="principal_broker">
              <div className="top_principal d-flex">
                <div className="left_principal image_uploader">
                  <img
                    src={imagePreview}
                    alt="Uploaded"
                    className="responsive_image"
                  />
                  <input
                    type="file"
                    onChange={handleImageChange}
                    style={{ display: "none" }}
                    id="imageInput"
                  />
                  <label htmlFor="imageInput" className="edit-icon-image">
                    <img src={Edit} />
                  </label>
                </div>
                <div className="right_principal title_bar_setting">
                  <div className="single_entry">
                    <label>
                      Default Author Name
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Premier Reality Group"
                      />{" "}
                    </label>
                  </div>

                  <div className="principal_text_area">
                    <h3>Code Here </h3>
                    <div className="form-group">
                      <textarea
                        id="ampHeaderTitle"
                        className="form-control area_control"
                        placeholder="As a dedicated and knowledgeable real estate agent I can provide you with the highly specialized information that you will need to make the right decision. Its the combination of this unique market-knowledge and excellent negotiating skills that enable me to get you the optimal price for any property you plan to sell or buy."
                      />
                    </div>
                  </div>
                </div>

                <div className="contact_detail title_bar_setting">
                  <h3>Contact Details</h3>
                  <div className="contract_rows d-flex">
                    <div className="left_contact_dt">
                      <div className="single_entry">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Fax ID : allprofessionalsre"
                        />
                      </div>
                    </div>
                    <div className="right_cont_dt">
                      <div className="single_entry">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Skype ID : allprofessionalsre"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="contract_rows d-flex">
                    <div className="left_contact_dt">
                      <div className="single_entry">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="20001 SW Tualatin Valley Hwy Beaverton, OR 97003"
                        />
                      </div>
                    </div>
                    <div className="right_cont_dt">
                      <div className="single_entry">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Licence : CASDD45744CCS"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="selectors">
                    <h3>Number of Agents</h3>
                    <div className="contract_rows d-flex">
                      <div className="left_contact_dt">
                        <label className="custom-radio-container">
                          Independent
                          <input
                            type="radio"
                            name="agentType"
                            className="custom-radio"
                            onChange={() => setAgentType("independent")}
                            checked={agentType === "independent"}
                          />
                          <span className="radio-checkmark"></span>
                        </label>
                      </div>
                      <div className="right_cont_dt d-flex">
                        <label className="custom-radio-container">
                          Multi Agents
                          <input
                            type="radio"
                            name="agentType"
                            className="custom-radio"
                            onChange={() => setAgentType("multi")}
                            checked={agentType === "multi"}
                          />
                          <span className="radio-checkmark"></span>
                        </label>
                        {agentType === "multi" && (
                          <div className="hidden_selector">
                            <div>
                              <input
                                type="number"
                                value={value}
                                onChange={onInputChange}
                                className="form-control"
                              />
                              <Slider
                                min={0}
                                max={100}
                                value={value}
                                onChange={onSliderChange}
                                className="my-slider"
                              />
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="selector_btn">
                  <button className="btn update_btn">Explore Now</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BrokerLogin;
