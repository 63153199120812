const Api = {
  BASEURL: "https://oregonhomeseeker.com:3001/api",
  // BASEURL: "http://localhost:3001/api",

  IMAGE_URL: "https://oregonhomeseeker.com:3001/uploads",
  // BASEURL: "https://oregonhomeseeker.com:3001/api",

  // BASEURL: "http://192.168.1.16:3001/api",
  // BASEURL: "http://192.168.1.38:3001/api",
};
export default Api;
