import "./App.css";
import axios from "axios";
import moment from "moment";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import ProtectedRoute from "./Protectedroute";
import Url from "../src/components/Element/Api";
import Support from "./components/Support/Support";
import TeamMates from "./components/Realtor/TeamMates";
import Profile from "./components/BrokerProfile/Profile";
import PageNotFound from "./components/Element/PageNotFound";
import ManageBroker from "./components/ManageBroker/ManageBroker";
import Transactions from "./components/Transactions/Transactions";
import Packages from "./components/DashboardArea/Packages/Packages";
import Listings from "./components/DashboardArea/Listings/Listings";
import DashboardArea from "./components/DashboardArea/DashboardArea";
import LoginRegister from "./components/LoginRegister/LoginRegister";
import Posts from "./components/DashboardArea/BlogPages/Posts/Posts";
import RealtorSignUp from "./components/RealtorProcess/RealtorSignup";
import ForgotPassword from "./components/LoginRegister/ForgotPassword";
import RealtorProfile from "./components/RealtorProcess/RealtorProfile";
import Search from "./components/DashboardArea/SettingPages/Search/Search";
import EmailVerification from "./components/LoginRegister/EmailVerification";
import BrokerLogin from "./components/LoginRegister/BrokerLogin/BrokerLogin";
import SingleBlog from "./components/DashboardArea/BlogPages/Posts/SingleBlog";
import ManageTags from "./components/DashboardArea/BlogPages/Posts/ManageTags";
import Branding from "./components/DashboardArea/SettingPages/Branding/Branding";
import AmpPages from "./components/DashboardArea/SettingPages/AmpPages/AmpPages";
import MetaTags from "./components/DashboardArea/SettingPages/MetaTags/MetaTags";
import RealtorProfileSetup from "./components/RealtorProcess/RealtorProfileSetup";
import AdminSetPayments from "./components/DashboardArea/SetPayments/SetPayments";
import ExtraCode from "./components/DashboardArea/SettingPages/ExtraCode/ExtraCode";
import RealtorProfileActivityLog from "./components/RealtorProcess/RealtorProfileActivityLog";
import BrokerProfileEdit from "./components/DashboardArea/BrokerProfileEdit/BrokerProfileEdit";
import BlogDefaultSetting from "./components/DashboardArea/BlogPages/Posts/BlogDefaultSetting";
import ListingDisplay from "./components/DashboardArea/SettingPages/ListingDisplay/ListingDisplay";
import GoogleAnalytics from "./components/DashboardArea/SettingPages/GoogleAnalytics/GoogleAnalytics";
import { setLogOut, setProfileImage, setRefreshToken, setRegToken, setTokenExpTime } from "./components/Redux/Action/Authaction";

function App() {
  let today = new Date();
  const dispatch = useDispatch();
  const tokenExpTime = useSelector((state) => state.tokenExpTime);
  const refreshToken = useSelector((state) => state.refreshToken);
  let currentTime = moment.utc(today).local().format("YYYY-MMM-DD h:mm:ss A");
  var expTime = moment.utc(tokenExpTime).local().format("YYYY-MMM-DD h:mm:ss A");
  let remainingTime = moment(expTime).diff(moment(currentTime), "seconds");
  const events = ["load", "mousemove", "mousedown", "click", "scroll", "keypress"];
  let timer;

  const handleLogout = () => {
    axios
      .delete(`${Url.BASEURL}/admin/logout`, {
        data: {
          refreshToken: refreshToken,
        },
      })
      .then(() => {
        dispatch(setLogOut());
        dispatch(setProfileImage(null));
        dispatch(setTokenExpTime(null));
        dispatch(setRefreshToken(null));
        window.location.href = "/";
      });
  };

  const handleRefreshToken = () => {
    axios
      .post(`${Url.BASEURL}/admin/refresh-token`, { refreshToken: refreshToken })
      .then((res) => {
        dispatch(setRegToken(res?.data?.accessToken));
        dispatch(setTokenExpTime(res?.data?.expirationTime));
      })
      .catch(() => {
        handleLogout();
      });
  };

  useEffect(() => {
    const interval = setInterval(() => {
      handleRefreshToken();
    }, 115 * 60 * 1000);
    return () => clearInterval(interval);
  }, [refreshToken]);

  useEffect(() => {
    if (remainingTime < 0 && refreshToken !== null) {
      handleRefreshToken();
    }
  }, [refreshToken, remainingTime]);

  const resetTimer = () => {
    if (timer) clearTimeout(timer);
  };

  const handleLogoutTimer = () => {
    timer = setTimeout(() => {
      resetTimer();
      Object.values(events).forEach((item) => {
        window.removeEventListener(item, resetTimer);
      });
      handleLogout();
    }, 120 * 60 * 1000); // 10000ms = 10secs. You can change the time.
  };

  useEffect(() => {
    Object.values(events).forEach((item) => {
      window.addEventListener(item, () => {
        resetTimer();
        handleLogoutTimer();
      });
    });
  }, []);

  return (
    <div className="app-container">
      <BrowserRouter>
        <Routes>
          <Route path="*" element={<PageNotFound />} />
          <Route path="/" index element={<LoginRegister />} />
          <Route path="/broker-login" index element={<BrokerLogin />} />
          <Route path="/realtor-signup" index element={<RealtorSignUp />} />
          <Route path="/forgot-password" index element={<ForgotPassword />} />
          <Route path="email-verification" index element={<EmailVerification />} />

          <Route element={<ProtectedRoute />}>
            <Route path="dashboard" index element={<DashboardArea />} />
            <Route path="posts" index element={<Posts />} />
            <Route path="search" index element={<Search />} />
            <Route path="/profile" index element={<Profile />} />
            <Route path="/support" index element={<Support />} />
            <Route path="packages" index element={<Packages />} />
            <Route path="listings" index element={<Listings />} />
            <Route path="branding" index element={<Branding />} />
            <Route path="amp-pages" index element={<AmpPages />} />
            <Route path="meta-tags" index element={<MetaTags />} />
            <Route path="extra-code" index element={<ExtraCode />} />
            <Route path="/profile/:id" index element={<Profile />} />
            <Route path="single-blog" index element={<SingleBlog />} />
            <Route path="manage-tags" index element={<ManageTags />} />
            <Route path="manage-broker" index element={<ManageBroker />} />
            <Route path="/transactions" index element={<Transactions />} />
            <Route path="listing-display" index element={<ListingDisplay />} />
            <Route path="/set-payments" index element={<AdminSetPayments />} />
            <Route path="realtor-profile" index element={<RealtorProfile />} />
            <Route path="google-analytics" index element={<GoogleAnalytics />} />
            <Route path="realtors-under-broker/:id" index element={<TeamMates />} />
            <Route path="/realtor-profile/:id" index element={<RealtorProfile />} />
            <Route path="broker-profile-edit" index element={<BrokerProfileEdit />} />
            <Route path="blog-default-setting" index element={<BlogDefaultSetting />} />
            <Route path="realtor-profile-setup" index element={<RealtorProfileSetup />} />
            <Route path="realtor-profile-activity-log" index element={<RealtorProfileActivityLog />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
