import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import Url from "../Element/Api";
import useAuth from "../../hooks/useAuth";
import { LineChart, Line } from "recharts";
import { useParams } from "react-router-dom";
import Arrow from "../../assets/upper-arrow.svg";
import ModalMessage from "../ModalMessage/Modal";
import { handleDropdownClose } from "../../utils/utils";

const blockData = [
  {
    iconClass: "icon-vector4",
    number: 0,
    text: "",
    chartData: [{ uv: 300 }, { uv: 400 }, { uv: 250 }, { uv: 310 }, { uv: 280 }, { uv: 225 }, { uv: 400 }],
    color: "#007AFF",
    blockClass: "block-color1",
  },
  {
    iconClass: "icon-vector0",
    number: 0,
    text: "Leads",
    chartData: [{ uv: 200 }, { uv: 100 }, { uv: 200 }, { uv: 310 }, { uv: 20 }, { uv: 325 }, { uv: 800 }],
    color: "#FF9600",
    blockClass: "block-color2",
  },
  {
    iconClass: "icon-Vector1",
    number: 0,
    text: "Total Transactions",
    chartData: [{ uv: 300 }, { uv: 400 }, { uv: 250 }, { uv: 310 }, { uv: 280 }, { uv: 225 }, { uv: 1200 }],
    color: "#28AC47",
    blockClass: "block-color3",
  },
  {
    iconClass: "icon-Vector2",
    number: 0, // Placeholder value, will be updated from API
    text: "Active Listings",
    chartData: [{ uv: 300 }, { uv: 500 }, { uv: 100 }, { uv: 600 }, { uv: 50 }, { uv: 100 }, { uv: 400 }],
    color: "#ED4949",
    blockClass: "block-color4",
  },
  {
    isDifferent: true,
  },
];

const options = ["Active", "Inactive"];

const BrokerProfileHeader = ({ onSetProfileLoading, onSetBrokerData }) => {
  const { id } = useParams();
  const [config] = useAuth();
  const dropdownRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [disable, setDisable] = useState(false);
  const [leadsCount, setLeadsCount] = useState(0);
  const [brokerword, setBrokerword] = useState(0);
  const [modalType, setModalType] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [totalRealtor, setTotalRealtor] = useState(0);
  const [activeListings, setActiveListings] = useState(0);
  const [totalPaidAmount, setTotalPaidAmount] = useState(0);
  const [selectedOption, setSelectedOption] = useState(null);
  const [transactionCount, setTransactionCount] = useState(0);

  const updatedBlockData = [...blockData];
  updatedBlockData[3].number = activeListings;
  updatedBlockData[2].number = transactionCount;
  updatedBlockData[1].number = leadsCount;
  updatedBlockData[0].number = totalPaidAmount.toFixed(2);
  updatedBlockData[0].text = brokerword;
  updatedBlockData[2].text = totalRealtor;

  useEffect(() => {
    fetchRealtorData();
  }, [id]);

  const fetchRealtorData = async () => {
    try {
      onSetProfileLoading(true);
      const response = await axios.get(Url.BASEURL + `/admin/get-broker/${id}`, config);
      onSetBrokerData(response.data?.data?.broker);
      setActiveListings(response.data.data.propertyCount);
      setTransactionCount(response.data.data.realtorCount);
      setLeadsCount(response.data.data.leadsCount);
      setTotalPaidAmount(response.data.data.totalRevenue);
      setBrokerword(response.data.data.brokerword);
      setTotalRealtor(response.data.data.totalRealtor);
      onSetProfileLoading(false);
      setSelectedOption(response.data?.data?.broker.status ? "Active" : "Inactive");
    } catch (error) {
      onSetProfileLoading(false);
      console.error("Error fetching realtor data:", error);
    } finally {
      onSetProfileLoading(false);
    }
  };

  const onOptionClicked = (value) => () => {
    if (value === "Active" || value === "Inactive") {
      setModalType(value.toLowerCase());
      setShowModal(true);
    } else {
      setSelectedOption(value);
      setIsOpen(false);
    }
  };

  const handleModalButtonClick = async () => {
    const newStatus = modalType === "active" ? "Active" : "Inactive";
    const status = modalType === "active" ? true : false;
    setDisable(true);
    axios
      .post(Url.BASEURL + `/admin/change-broker-status`, { brokerId: id, status: status }, config)
      .then((res) => {
        onSetBrokerData((prevData) => ({
          ...prevData,
          status: status ? 1 : 0,
        }));
       setShowModal(false);
        setSelectedOption(newStatus);
        setDisable(false);
        
      })
      .catch((error) => {
        setDisable(false);
        setShowModal(false);
        console.error("Error on Updating status:", error);
      });
  };

  useEffect(() => {
    const handleClose = () => {
      setIsOpen(false);
    };
    handleDropdownClose(dropdownRef, handleClose);
  }, [dropdownRef]);

  return (
    <div className="main_flexing">
      <div className="sub_flexing d-flex">
        {blockData.map((block, index) =>
          block.isDifferent ? (
            <div className={`custom_structure ${block.blockClass}`} key={index}>
              <div className="new-block">
                <h2>Broker Status</h2>

                <div className="custom-dropdown">
                  <div className="dropdown-header" onClick={() => setIsOpen(!isOpen)}>
                    {selectedOption || "Select an option"}
                    <img src={Arrow} alt="Dropdown Arrow" className="dropdown-arrow" onChange={(e) => setSelectedOption(e.target.value)} />
                  </div>

                  {isOpen && (
                    <div ref={dropdownRef} className="dropdown-list-container">
                      <ul className="dropdown-list">
                        {options.map((option) => (
                          <li onClick={onOptionClicked(option)} key={Math.random()}>
                            {option}
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                </div>
              </div>
            </div>
          ) : (
            <div className={` ${block.blockClass} line-charts-data`} key={index}>
              <div className="block">
                <div className="icon-bg">
                  <span className={block.iconClass}></span>
                </div>
                <div className="chart-space">
                  <LineChart width={100} height={50} data={block.chartData}>
                    <Line type="monotone" dataKey="uv" stroke={block.color} strokeWidth={3} dot={false} />
                  </LineChart>
                  <p>{block.chartText}</p>
                </div>
                <div className="number">{index === 0 ? `$${block.number}` : index === 1 ? `${block.number}` : block.number}</div>
                <p className="blockp">{block.text}</p>
              </div>
            </div>
          )
        )}

        <ModalMessage
          show={showModal}
          disable={disable}
          leftButton="Cancel"
          handleSave={handleModalButtonClick}
          rightButton={modalType === "active" ? "Active" : "Inactive"}
          message={`Do you really want to ${modalType === "active" ? "Active" : "Inactivate"} this broker? This process can change anytime.`}
          handleClose={() => {
            setShowModal(false);
            setDisable(false);
          }}
        />
      </div>
    </div>
  );
};

export default BrokerProfileHeader;
