import React, { useState } from "react";
import ClipLoader from "react-spinners/ClipLoader";
import { useNavigate } from "react-router-dom";
import UserImage from "../../../assets/realtor.png";
import ArrowUp from "../../../assets/arrow-up.svg";
import PendingProfileModal from "../../ModalMessage/PendingProfileModal";
import ReactPaginate from "react-paginate";
import { NextLabel, PreviousLabel } from "../../../utils/utils";

const BrokerListSection = ({ from, newData, loading, totalPage, onSetCurrentPage }) => {
  const navigate = useNavigate();
  const [isPendingPopupVisible, setIsPendingPopupVisible] = useState(false);
  const listData = from === "dashboard" ? newData.slice(0, 5) : newData;

  return (
    <div>
      {loading ? (
        <div className="text-center">
          <ClipLoader color="#80C342" loading={loading} size={from === "dashboard" ? 100 : 150} aria-label="Loading Spinner" data-testid="loader" />
        </div>
      ) : (
        <div className="manage_broker_table table-responsive">
          <table className="table-borderless w-100">
            <thead>
              <tr>
                <th>Company Name</th>
                {from !== "dashboard" && <th>Payment</th>}
                <th>Status</th>
                <th>Members</th>
                <th>Revenue </th>
                {from === "dashboard" && <th>Active Properties</th>}
              </tr>
            </thead>
            <tbody>
              {listData?.length === 0 ? (
                <tr className="text-center">
                  <td
                    colSpan="9"
                    style={{
                      fontSize: 16,
                      marginTop: "10px",
                      alignSelf: "center",
                      textAlign: "center",
                    }}
                  >
                    No Broker Found
                  </td>
                </tr>
              ) : (
                listData?.map((item, index) => (
                  <tr
                    key={index}
                    onClick={() => {
                      if (item?.broker?.subscribed) {
                        const brokerName = item?.broker.firstname + " " + item?.broker?.lastname;
                        localStorage.setItem("brokerId", item.broker._id);
                        localStorage.setItem("brokerName", brokerName);
                        localStorage.setItem("navigateFrom", from);
                        navigate(`/profile/${item.broker._id}`);
                      } else {
                        setIsPendingPopupVisible(true);
                      }
                    }}
                    className="custom-link-row"
                  >
                    <td className="text-capitalize">{item.broker.companyName ?? "N/A"}</td>
                    {from !== "dashboard" && <td className={`mutual_text ${item.broker.subscribed ? "greener" : "yellowish"}`}>{item.broker.subscribed ? "Successful" : "Pending"}</td>}
                    <td>
                      <div className={`mutual_bf ${item.broker.subscribed ? (item.broker.status ? "greener_btn" : "yalner") : "yellow_btn"}`}>{item.broker.subscribed ? (item.broker.status ? "Active" : "Inactive") : "Pending"}</div>
                    </td>
                    <td>
                      <div className="list_images d-flex">
                        {item.realtors.length > 0 ? (
                          item.realtors.slice(0, 5).map((realtor, idx) => (
                            <img
                              key={idx}
                              src={realtor.imageURL || UserImage}
                              alt={`realtor-${idx}`}
                              onError={(e) => {
                                e.target.src = UserImage;
                              }}
                            />
                          ))
                        ) : (
                          <p className="na-text">N/A</p>
                        )}
                      </div>
                    </td>

                    {from === "dashboard" ? (
                      <td>{item.totalRevenue.toFixed(2) ? item.totalRevenue.toFixed(2) : "0"} </td>
                    ) : (
                      <td className="up_move">
                        <span className="upimage">
                          <img src={ArrowUp} alt="Sale icon" />
                        </span>
                        {item.totalRevenue.toFixed(2) ?? "0"}
                      </td>
                    )}
                    {from === "dashboard" && <td>{item.propertyCount ? item.propertyCount : "0"}</td>}
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
      )}
      {from !== "dashboard" && totalPage > 1 && (
        <div className="all_pagination">
          <ReactPaginate
            previousLabel={<PreviousLabel />}
            nextLabel={<NextLabel />}
            breakLabel={"..."}
            pageCount={totalPage}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={(e) => {
              onSetCurrentPage(e.selected + 1);
            }}
            containerClassName={"pagination"}
            activeClassName={"active"}
          />
        </div>
      )}
      <PendingProfileModal from="broker" show={isPendingPopupVisible} handleClose={() => setIsPendingPopupVisible(false)} />
    </div>
  );
};

export default BrokerListSection;
