import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import "./Packages.css";
import Url from "../../Element/Api.js";
import Sliders from "../../../assets/s3.svg";
import useAuth from "../../../hooks/useAuth.js";
import Comments from "../../../assets/comments.svg";
import BaseLayout from "../../../layouts/BaseLayout.js";
import { handleDropdownClose } from "../../../utils/utils.js";

const AgentdropdownOptions = [
  // { type: "heading", content: "Payment" },
  { type: "option", content: "All", value: " " },
  { type: "option", content: "High To Low", value: "highToLow" },
  { type: "option", content: "Low To High", value: "lowToHigh" },
];

function PackagesInfo() {
  const [config] = useAuth();
  const dropdownRef = useRef(null);
  const [posts, setPosts] = useState([]);
  const [sortBy, setSortBy] = useState("");
  const [tableData, setTableData] = useState([]);
  const [featureName, setFeatureName] = useState("");
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(" ");
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const PostsDropdown = () => {
    if (!isDropdownOpen) {
      setIsDropdownOpen(true);
    }
  };

  useEffect(() => {
    const handleClose = () => {
      setIsDropdownOpen(false);
    };
    handleDropdownClose(dropdownRef, handleClose);
  }, [dropdownRef]);

  const fetchData = async () => {
    try {
      const response = await axios.get(`${Url.BASEURL}/admin/package-pricing?featureName=${featureName}&sortBy=${sortBy}`, config);
      setTableData(response.data);
    } catch (error) {
      console.log("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [featureName, sortBy]);

  const toggleDropdown = (e, postId) => {
    e.preventDefault();
    e.stopPropagation();
    setDropdownOpen(dropdownOpen === postId ? false : postId);
  };

  const handleOptionSelect = (option) => {
    setSelectedOption(option.content);
    setSortBy(option.value);
    setIsDropdownOpen(false);
  };

  const renderTableRows = () => {
    if (!tableData.packages || tableData.packages.length === 0) {
      return (
        <tr>
          <td colSpan="3">Loading...</td>
        </tr>
      );
    }

    return tableData.packages.map((row, index) => (
      /*   <tr key={index}>
          <td>{row.featureName}</td>
          <td align="center">{<input type="checkbox" id={index} className="invoice-checks tivker" />}</td>
          <td align="center">{<input type="checkbox" id={index} className="invoice-checks tivker" />}</td>
          <td>{row.price}</td>
          <td>{(row.status) ? 'true': 'false'}</td>
        </tr> */
      <tr key={index}>
        <td>{row.featureName}</td>
        <td align="left">
          <input type="checkbox" id={`check1-${index}`} className="invoice-checks tivker" hidden />
          <label htmlFor={`check1-${index}`} className="custom-label"></label>
        </td>
        <td align="left">
          <input type="checkbox" id={`check2-${index}`} className="invoice-checks tivker" hidden />
          <label htmlFor={`check2-${index}`} className="custom-label"></label>
        </td>
        <td>${row.price}</td>
        <td>{row.status ? "true" : "false"}</td>
      </tr>
    ));
  };

  return (
    <BaseLayout addClass={true}>
      <div className="mutual_blocks amp-pages">
        <h2>Packages Listing</h2>
        <div className="blog_add">
          <Link to="/">
            <div className="Add_new_blog btn update_btn">Add Package</div>
          </Link>
        </div>
        <div className="posting_area">
          <div className="row mb-3 padding salining">
            <div className="col-md-6 col-sm-12 d-flex justify-content-start align-items-center link_sett">
              <div className="results_realtor transactions_listing halfia">
                All ({tableData.count})
                {/* <Link to="/published" className="text-primary ms-1">
                  <span>Published</span> (10)
                </Link> */}
              </div>
            </div>
            <div className="col-md-6 col-sm-12 d-flex justify-content-end mku">
              <div className="search_area d-flex">
                <div className="search_bloxk">
                  <input
                    type="search"
                    className="form-control me-2"
                    placeholder="Search by Feature Name"
                    value={featureName}
                    onChange={(e) => {
                      setFeatureName(e.target.value);
                    }}
                  />
                </div>
                {/* <div className="btn_block">
                  <button className="btn btn-primary">Search</button>
                </div> */}
              </div>
              <div className="filter_area">
                <div className="agent_dropdown">
                  <div ref={dropdownRef} className={`drop_data ${isDropdownOpen ? "open" : ""}`}>
                    <div className="selected_option" onClick={PostsDropdown} style={{ backgroundImage: `url(${Sliders})` }}>
                      Filter
                    </div>
                    {isDropdownOpen && (
                      <div className="drop_selection">
                        {AgentdropdownOptions.map((option, index) => (
                          <p key={index} onClick={() => handleOptionSelect(option)}>
                            {option.content}
                          </p>
                        ))}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Table */}
          <div className="row">
            <div className="col-lg-12">
              <div className="table-responsive">
                <table className="table">
                  <thead>
                    <tr>
                      <th scope="col">Feature Name</th>
                      <th scope="col">Single Agent</th>
                      <th scope="col">Broker Solution</th>
                      <th scope="col">Price</th>
                      <th scope="col">Status</th>
                      {/* <th scope="col">Edit</th> */}
                    </tr>
                  </thead>

                  <tbody>
                    {renderTableRows()}
                    {posts.map((post) => (
                      <tr key={post.id}>
                        <td>{post.featureName}</td>
                        <td className="">
                          <input type="checkbox" className="invoice-checks" />
                        </td>
                        <td className="author-tags">{post.tags.join(", ")}</td>
                        <td className="font_set">
                          <div className="d-block">
                            {/* {post.date.split("\n")[0]} */}
                            <input type="checkbox" className="invoice-checks" />
                          </div>
                          <div className="d-block">{post.date.split("\n")[1]}</div>
                        </td>
                        <td className="font_set commnts">
                          <img src={Comments} alt="Comments" />({post.comments})
                        </td>
                        {/* <td>
                                    <div className="dropdown dropper">
                                      <Link
                                        to="#"
                                        className="text-primary dropdown-toggle"
                                        role="button"
                                        id={`dropdownMenuLink-${post.id}`}
                                        onClick={(e) =>
                                          toggleDropdown(e, post.id)
                                        }
                                      >
                                        <img src={Dots} alt="Edit" />
                                      </Link>

                                      {dropdownOpen === post.id && (
                                        <div
                                          className={`dropdown-menu${
                                            dropdownOpen === post.id
                                              ? " show"
                                              : ""
                                          }`}
                                          aria-labelledby={`dropdownMenuLink-${post.id}`}
                                        >
                                          <Link
                                            className="dropdown-item"
                                            to="/view-path"
                                          >
                                            <span class="icon-eye1"></span> View
                                          </Link>
                                          <Link
                                            className="dropdown-item"
                                            to={post.editLink}
                                          >
                                            <span class="icon-Pencil1"></span>{" "}
                                            Edit
                                          </Link>
                                          <button
                                            className="dropdown-item"
                                            onClick={() =>
                                              handleDelete(post.id)
                                            }
                                          >
                                            <span class="icon-Recycle-Bin"></span>{" "}
                                            Delete
                                          </button>
                                        </div>
                                      )}
                                    </div>
                                  </td> */}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </BaseLayout>
  );
}

export default PackagesInfo;
