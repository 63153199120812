
import React from 'react';
import './Realtor.css';
import Logo from '../../assets/logo.png';
import Arrow from '../../assets/arrow.png';

import { Link } from 'react-router-dom';





function RealtorSignUp() {


  

  return (
    <div className="login-register">
      <div className="login-header">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-6 col-sm-6">
              <div className="logo"> <img src={Logo} alt="Logo" /></div>
            </div>
            <div className="col-lg-6 col-sm-6">
              <div className="mobile">(415) 144 0781</div>
            </div>
          </div>
        </div>
      </div>

      <div className="container container_bg">
      <div className="row ">
<div className="col-lg-4 offset-lg-1 com-sm-12 md_none" >
<div className="log_text_one">
Simplify Unlock Efficiency and Elevate
Your Real Estate Business with with
</div>
<div className="log_text_two">WOVNN</div>
<div className="log_image"><img src={Arrow} alt="Logo" /></div>
<div className="bottom_text_one">Get Started Today for Seamless Property Management</div>
<div className="bottom_multiple_text">
    <div className="b1">No card details required</div>
    <div className="b1 b2">Senior technical architect's assistance</div>
    <div className="b1 b3">Keep all the work secure</div>
</div>
</div>
<div className="col-lg-4 offset-lg-2 onset-lg-1 com-sm-12">

<div className="loginRegister signup_realtor">
            <div className="d-flex log_reg">
             
                <button className="btn px-4">Signup</button>
            </div>

      
            <div className="section mt-4 register">
              
            <form>
    <div className="row mb-3">
        <div className="col mcol">
            <input type="text" className="form-control" placeholder="First Name" />
        </div>
        <div className="col mcol">
            <input type="text" className="form-control" placeholder="Last Name" />
        </div>
    </div>
    <div className="mb-3">
        <input type="text" className="form-control" placeholder="Phone No*" />
    </div>
    <div className="mb-3">
        <input type="email" className="form-control" placeholder="Email Address*" />
    </div>
    <div className="mb-3">
        <input type="password" className="form-control" placeholder="Password*" />
    </div>
    <button type="submit" className="btn btn-primary logreg_btn">
  <Link to="/email-verification" >
  Submit
  </Link>
</button>
</form>

            </div>










        </div>


</div>
</div>
    
      </div>

  




    </div>
  );
}

export default RealtorSignUp;


