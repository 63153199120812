import React, { useState } from "react";
import Slider from "rc-slider";
import "./BrokerProfileEdit.css";
import "rc-slider/assets/index.css";
import Header from "../Header/Header";
import Sidebar from "../Sidebar/Sidebar";
import Edit from "../../../assets/pencil.svg";
import Eye from "../../../assets/password-eye.svg";
import ChangePasswordModal from "../../ModalMessage/ChangePasswordModal";

function BrokerProfileEdit() {
  const [value, setValue] = useState(10);
  const [agentType, setAgentType] = useState("independent");
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [isPasswordModalVisible, setIsPasswordModalVisible] = useState(false);
  const [imagePreview, setImagePreview] = useState("https://via.placeholder.com/150");

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const closePasswordModal = () => {
    setIsPasswordModalVisible(false);
  };

  return (
    <div className="dashboard-container">
      <div className="sidebar-main">
        <Sidebar />
      </div>
      <div className="header-dasboard main_container">
        <Header />
        <div className="dashboard">
          <div className="container-fluid main_container">
            <div className="row">
              <div className="col-lg-12">
                <div className="mutual_blocks team_mamet">
                  <div className="login-register broker_edit_profile">
                    <div className="container-fluid custom_principal_page broker_profile_only">
                      <div className="row ">
                        <div className="col-lg-12">
                          <div className="principal_broker">
                            <div className="top_principal d-flex">
                              <div className="left_principal image_uploader">
                                <img src={imagePreview} alt="Uploaded" className="responsive_image" />
                                <input type="file" onChange={handleImageChange} style={{ display: "none" }} id="imageInput" />
                                <label htmlFor="imageInput" className="edit-icon-image">
                                  <img src={Edit} alt="" />
                                </label>
                              </div>
                              <div className="right_principal title_bar_setting">
                                <div className="contact_detail title_bar_setting">
                                  <div className="contract_rows d-flex">
                                    <div className="left_contact_dt">
                                      <div className="single_entry">
                                        <label>
                                          First Name
                                          <input type="text" className="form-control" placeholder="John" />
                                        </label>
                                      </div>
                                    </div>
                                    <div className="right_cont_dt">
                                      <div className="single_entry">
                                        <label>
                                          Last Name
                                          <input type="text" className="form-control" placeholder="Scott" />
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="contract_rows d-flex">
                                    <div className="left_contact_dt">
                                      <div className="single_entry">
                                        <label>
                                          Email
                                          <input type="text" className="form-control" placeholder="premierrealtygroup@gmail.com" />
                                        </label>
                                      </div>
                                    </div>
                                    <div className="right_cont_dt">
                                      <div className="single_entry eye_set">
                                        <p
                                          className="change_passs"
                                          onClick={() => {
                                            setIsPasswordModalVisible(true);
                                          }}
                                          id="ChangePass"
                                        >
                                          Change Password
                                        </p>

                                        <label>
                                          Password
                                          <input type={isPasswordVisible ? "text" : "password"} className="form-control" placeholder="***************" />
                                        </label>
                                        <div
                                          className="password_eye"
                                          onClick={() => {
                                            setIsPasswordVisible(!isPasswordVisible);
                                          }}
                                        >
                                          <img src={Eye} alt="eye" />
                                        </div>
                                      </div>
                                    </div>

                                    <div className="principal_text_area">
                                      <h3>Bio </h3>
                                      <div className="form-group">
                                        <textarea id="ampHeaderTitle" className="form-control area_control" placeholder="As a dedicated and knowledgeable real estate agent I can provide you with the highly specialized information that you will need to make the right decision. Its the combination of this unique market-knowledge and excellent negotiating skills that enable me to get you the optimal price for any property you plan to sell or buy." />
                                      </div>
                                    </div>

                                    <div className="contact_detail title_bar_setting">
                                      <h3>Contact Details</h3>
                                      <div className="contract_rows d-flex">
                                        <div className="left_contact_dt">
                                          <div className="single_entry">
                                            <input type="text" className="form-control" placeholder="Fax ID : allprofessionalsre" />
                                          </div>
                                        </div>
                                        <div className="right_cont_dt">
                                          <div className="single_entry">
                                            <input type="text" className="form-control" placeholder="Skype ID : allprofessionalsre" />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="contract_rows d-flex">
                                        <div className="left_contact_dt">
                                          <div className="single_entry">
                                            <input type="text" className="form-control" placeholder="20001 SW Tualatin Valley Hwy Beaverton, OR 97003" />
                                          </div>
                                        </div>
                                        <div className="right_cont_dt">
                                          <div className="single_entry">
                                            <input type="text" className="form-control" placeholder="Licence : CASDD45744CCS" />
                                          </div>
                                        </div>
                                      </div>

                                      <div className="selectors">
                                        <h3>Number of Agents</h3>
                                        <div className="contract_rows d-flex">
                                          <div className="left_contact_dt">
                                            <label className="custom-radio-container">
                                              Independent
                                              <input type="radio" name="agentType" className="custom-radio" onChange={() => setAgentType("independent")} checked={agentType === "independent"} />
                                              <span className="radio-checkmark"></span>
                                            </label>
                                          </div>
                                          <div className="right_cont_dt d-flex">
                                            <label className="custom-radio-container">
                                              Multi Agents
                                              <input type="radio" name="agentType" className="custom-radio" onChange={() => setAgentType("multi")} checked={agentType === "multi"} />
                                              <span className="radio-checkmark"></span>
                                            </label>
                                            {agentType === "multi" && (
                                              <div className="hidden_selector">
                                                <div>
                                                  <input
                                                    type="number"
                                                    value={value}
                                                    onChange={(e) => {
                                                      setValue(e.target.value);
                                                    }}
                                                    className="form-control"
                                                  />
                                                  <Slider
                                                    min={0}
                                                    max={100}
                                                    value={value}
                                                    onChange={(newValue) => {
                                                      setValue(newValue);
                                                    }}
                                                    className="my-slider"
                                                  />
                                                </div>
                                              </div>
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="selector_btn">
                                      <button className="btn update_btn">Explore Now</button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="selector_btn">
                        <button className="btn update_btn">Explore Now</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Change Password Modal */}
      <ChangePasswordModal isPasswordModalVisible={isPasswordModalVisible} closePasswordModal={closePasswordModal} />
    </div>
  );
}

export default BrokerProfileEdit;
