import React from "react";

const RealtorTransactionData = ({ transaction }) => {
  return (
    <>
      {transaction.realtorName && transaction.feetype && (
      <tr>
        <td>
        Realtor SetUp Fees: <span className="blacking">${transaction.brokerSetUpFee}</span>
        </td>
        <td>
          <div className="status">${transaction.wovnnCut}</div>
          <br />
          <div className="success super_class">{transaction.status === "succeeded" ? "Successful" : "Failed"}: </div>
          <div className="date_set super_class">
            {new Date(transaction.date).toLocaleDateString("en-US", {
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
            })}
          </div>
        </td>
      </tr>)}

      {transaction.realtorName && !transaction.feetype && (
      <tr>
        <td>
            Realtor Monthly Fees: <span className="blacking">${transaction.brokerFinalAmount}</span>
          </td>
        <td>
          <div className="status">${transaction.wovnnCut}</div>
          <br />
          <div className="success super_class">{transaction.status === "succeeded" ? "Successful" : "Failed"}: </div>
          <div className="date_set super_class">
            {new Date(transaction.date).toLocaleDateString("en-US", {
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
            })}
          </div>
        </td>
      </tr>)}
    </>
  );
};

export default RealtorTransactionData;
