import React, { useState, useEffect } from "react";
import "./Support.css";
import axios from "axios";
import Url from "../Element/Api";
import "react-quill/dist/quill.snow.css";
import useAuth from "../../hooks/useAuth";
import { useNavigate } from "react-router-dom";
import ModalMessage from "../ModalMessage/Modal";
import BaseLayout from "../../layouts/BaseLayout";

const Support = () => {
  const [config] = useAuth();
  const navigate = useNavigate();
  const [error, setError] = useState();
  const [showModal, setShowModal] = useState(false);
  const [supportData, setSupportData] = useState({
    supportPhone: "",
    supportEmail: "",
    supportFax: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setError((prev) => ({ ...prev, [name]: "" }));
    setSupportData({ ...supportData, [name]: value });
  };

  useEffect(() => {
    axios
      .get(Url.BASEURL + `/admin/help-centre`, config)
      .then((response) => {
        const { data } = response;
        const helpAndSupportData = data.helpCentre.helpAndSupport;

        setSupportData({
          supportPhone: helpAndSupportData.supportPhone || "",
          supportFax: helpAndSupportData.supportFax || "",
          supportEmail: helpAndSupportData.supportEmail || "",
        });
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  const handleSave = (e) => {
    e.preventDefault();
    const postData = {
      supportPhone: supportData.supportPhone,
      supportEmail: supportData.supportEmail,
      supportFax: supportData.supportFax,
    };

    axios
      .post(Url.BASEURL + `/admin/help-centre`, postData, config)
      .then((response) => {
        setShowModal(false);
        navigate("/dashboard");
      })
      .catch((err) => {
        const errors = err?.response?.data?.errors || "Failed to update data. Please try again.";
        setShowModal(false);
        setError(errors);
      });
  };

  const breadcrumbData = [{ label: "Support" }];

  return (
    <BaseLayout addClass={true} breadcrumbData={breadcrumbData}>
      <div className="mutual_blocks amp-pages single_posts">
        <h2>For Support</h2>
        <p className="supporter">Instructions: Use the content editing window to compose and format your text as desired. You can add links, pictures, and even edit the html. When done, click “Save this post” to save your changes and view your post.</p>

        <form>
          {/* {error ? (
            <div
              style={{
                textAlign: "center",
                margin: "10px 180px",
                background: "#FFCCCB",
                color: "red",
              }}
              className="alert "
              role="alert"
            >
              {error}
            </div>
          ) : null} */}

          <div className="post-data title_bar_setting ">
            <div className="post_detail d-flex">
              <div className="left_post_detail">
                <div className="single_entry">
                  <label>
                    Phone Number
                    <input type="text" name="supportPhone" className="form-control" value={supportData?.supportPhone} onChange={handleChange} placeholder="Enter your phone number" />
                  </label>
                  {error?.supportPhone && <div className="validation-error">{error?.supportPhone}</div>}
                </div>
              </div>
              <div className="right_post_detail">
                <div className="single_entry">
                  <label>
                    Email
                    <input type="text" name="supportEmail" className="form-control" value={supportData.supportEmail} onChange={handleChange} placeholder="Enter your email" />{" "}
                  </label>
                  {error?.supportEmail && <div className="validation-error">{error?.supportEmail}</div>}
                </div>
              </div>

              <div className="single_entry left_post_detail">
                <label>
                  Fax
                  <input type="text" name="supportFax" className="form-control" value={supportData.supportFax} onChange={handleChange} placeholder="Enter your fax number" />{" "}
                </label>
                {error?.supportFax && <div className="validation-error">{error?.supportFax}</div>}
              </div>
            </div>

            <div className="two_btns">
              <button
                type="submit"
                className="btn update_btn"
                onClick={(e) => {
                  e.preventDefault();
                  setShowModal(true);
                }}
              >
                Save
              </button>
            </div>
          </div>
        </form>
      </div>

      <ModalMessage
        message="Do you really want to save your support details?"
        leftButton="Cancel"
        rightButton="Save"
        show={showModal}
        handleSave={handleSave}
        handleClose={() => {
          setShowModal(false);
        }}
      />
    </BaseLayout>
  );
};

export default Support;
