import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import "./ManageBroker.css";
import Chat from "../Chat/Chat";
import Url from "../Element/Api";
import useAuth from "../../hooks/useAuth";
import Sliders from "../../assets/s3.svg";
import BaseLayout from "../../layouts/BaseLayout";
import DeleteAlert from "../DeleteAlert/DeleteAlert";
import BrokerListSection from "../DashboardArea/Dashboard/BrokerListSection";
import { handleDropdownClose } from "../../utils/utils";

const AgentdropdownOptions = [
  { type: "option", content: "All", value: " " },
  { type: "option", content: "Successful", value: "true" },
  { type: "option", content: "Pending", value: "false" },
  { type: "option", content: "Date Joined: Newest", value: "-1" },
  { type: "option", content: "Date Joined: Oldest", value: "1" },
];

const ManageBroker = () => {
  const [config] = useAuth();
  const dropdownRef = useRef(null);
  const [tableData, setTableData] = useState([]);
  const [newData, setNewData] = useState([]);
  const [totalPage, setTotalPage] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [size, setSize] = useState(10);
  const [searchTerm, setSearchTerm] = useState("");
  const [totalItem, setTotalItem] = useState(0);
  const [loading, setLoading] = useState(true);
  const [subscribed, setSubscribed] = useState("");
  const [createdAt, setCreatedAt] = useState("");
  const [showDeleteAlert, setShowDeleteAlert] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");
  const [showChat, setShowChat] = useState(false);

  const itemsPerPage = 12;
  const currentPageAdjusted = Math.max(currentPage, 1);
  const indexOfFirstItem = (currentPageAdjusted - 1) * itemsPerPage + 1;
  const indexOfLastItem = Math.min(currentPageAdjusted * itemsPerPage, totalItem);

  // To get the list of brokers with their realtors under admin, filter, search api
  const fetchData = async () => {
    setLoading(true);
    try {
      setLoading(true);
      let queryString = `?size=${size}`;
      const queryParams = [];
      if (searchTerm.trim() !== "") {
        queryParams.push(`searchTerm=${searchTerm}`);
      }
      if (subscribed.trim() !== "") {
        queryParams.push(`subscribed=${subscribed}`);
      }
      if (createdAt.trim() !== "") {
        queryParams.push(`createdAt=${createdAt}`);
      }
      queryParams.push(`page=${currentPage}`);

      // Append other query parameters to the query string
      if (queryParams.length > 0) {
        queryString += `&${queryParams.join("&")}`;
      }

      // Make the API request with the constructed query string
      const response = await axios.get(`${Url.BASEURL}/admin/broker-realtors${queryString}`, config);
      setNewData(response.data.brokersWithRealtors);
      setTotalItem(response.data.totalItems);
      setTotalPage(response.data.totalPages);
    } catch (error) {
      console.error("Error fetching data:", error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      fetchData();
    }, 1000);

    return () => clearTimeout(timer);
  }, [searchTerm, currentPage, subscribed, createdAt]);

  useEffect(() => {
    const handleClose = () => {
      setIsDropdownOpen(false);
    };
    handleDropdownClose(dropdownRef, handleClose);
  }, [dropdownRef]);

  const handleOptionSelect = (option) => {
    setSelectedOption(option.content);
    setIsDropdownOpen(false);
    if (option.value === " ") {
      setSubscribed("");
      setCreatedAt("");
    } else {
      if (option.value === "true" || option.value === "false") {
        setSubscribed(option.value);
        setCreatedAt("");
      } else if (option.value === "-1" || option.value === "1") {
        setCreatedAt(option.value);
        setSubscribed("");
      }
    }
  };

  const closeDeleteAlert = () => {
    setShowDeleteAlert(false);
    setItemToDelete(null);
  };

  const handleDelete = () => {
    if (itemToDelete) {
      const updatedTableData = tableData.filter((item) => item.companyName !== itemToDelete.companyName);
      setTableData(updatedTableData);
    }
    closeDeleteAlert();
  };

  const breadcrumbData = [{ label: "Manage Brokers" }];

  return (
    <BaseLayout breadcrumbData={breadcrumbData}>
      <div className="mutual_blocks harks">
        <h4>Manage Broker</h4>
        <div className="losting">
          <div className="row mb-3 padding salining kraling">
            <div className="col-md-6 col-sm-12 d-flex justify-content-start align-items-center link_sett">
              <div className="results_realtor transactions_listing ">{`${indexOfFirstItem}-${indexOfLastItem} of ${totalItem} Results`}</div>{" "}
            </div>
            <div className="col-md-6 col-sm-12 d-flex justify-content-end mku">
              <div className="search_area d-flex">
                <div className="search_bloxk">
                  <input
                    type="search"
                    className="form-control me-2"
                    placeholder="Search by Broker Name Or Company Name"
                    value={searchTerm}
                    onChange={(event) => {
                      setSearchTerm(event.target.value);
                    }}
                  />
                </div>
              </div>
              <div className="filter_area">
                <div className="agent_dropdown">
                  <div ref={dropdownRef} className={`drop_data ${isDropdownOpen ? "open" : ""}`}>
                    <div
                      className="selected_option"
                      style={{ backgroundImage: `url(${Sliders})` }}
                      onClick={() => {
                        if (!isDropdownOpen) {
                          setIsDropdownOpen(true);
                        }
                      }}
                    >
                      {selectedOption || "All"}
                    </div>
                    {isDropdownOpen && (
                      <div className="drop_selection">
                        {AgentdropdownOptions.map((option, index) => (
                          <p key={index} onClick={() => handleOptionSelect(option)}>
                            {option.content}
                          </p>
                        ))}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-12">
              <BrokerListSection
                from={"manageBroker"}
                newData={newData}
                loading={loading}
                totalPage={totalPage}
                onSetCurrentPage={(value) => {
                  setCurrentPage(value);
                }}
              />

              <DeleteAlert show={showDeleteAlert} handleClose={closeDeleteAlert} handleDelete={handleDelete} />

              <Chat
                show={showChat}
                handleClose={() => {
                  setShowChat(false);
                }}
                handleDelete={handleDelete}
              />
            </div>
          </div>
        </div>
      </div>
    </BaseLayout>
  );
};

export default ManageBroker;
