import React, { useState } from "react";
import axios from "axios";
import "./LoginRegister.css";
import Url from "../Element/Api";
import Yes from "../../assets/tick.svg";
import { Link } from "react-router-dom";
import Logo from "../../assets/logo.png";
import Arrow from "../../assets/arrow.png";

function ForgotPassword() {
  const [otp, setOtp] = useState("");
  const [error, setError] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [currentScreen, setCurrentScreen] = useState("send-otp");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [forgotPasswordEmail, setForgotPasswordEmail] = useState("");
  const [forgotPasswordStage, setForgotPasswordStage] = useState("initial");

  const handleForgotPasswordSubmit = async () => {
    try {
      const response = await axios.post(`${Url.BASEURL}/admin/forgot-password`, {
        email: forgotPasswordEmail,
      });

      if (response.status === 200) {
        setCurrentScreen("get-otp");
        setForgotPasswordStage("enterOTP");
      } else {
        setError(response.data.message);
      }
    } catch (err) {
      if (err.response) {
        setError(err.response.data.message);
      } else {
        console.error("Error during request setup:", err.message);
      }
    }
  };

  const handleVerifyOTPSubmit = async () => {
    try {
      const response = await axios.post(`${Url.BASEURL}/admin/verify-otp`, {
        email: forgotPasswordEmail,
      });

      if (response.status === 200) {
        setCurrentScreen("change-password");
        setForgotPasswordStage("changePassword"); // or set to the next stage as needed
      }
    } catch (err) {
      if (err.response) {
        const errorMessage = err.response.data.message;
        setError(errorMessage);
      }
    }
  };

  const handleResetPasswordSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${Url.BASEURL}/admin/reset-password`, {
        email: forgotPasswordEmail,
        newPassword: newPassword,
        confirmNewPassword: confirmNewPassword,
      });

      if (response.status === 200) {
        setCurrentScreen("password-changed-successfully");
        setForgotPasswordStage("passwordChanged"); // or set to the next stage as needed
      }
    } catch (err) {
      if (err.response) {
        setError(err.response.data.message);
      }
    }
  };

  return (
    <div className="login-register">
      <div className="login-header">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-6 col-sm-6">
              <div className="logo">
                <img src={Logo} alt="Logo" />
              </div>
            </div>
            <div className="col-lg-6 col-sm-6">
              <div className="mobile">(415) 144 0781</div>
            </div>
          </div>
        </div>
      </div>

      <div className="container container_bg">
        <div className="row ">
          <div className="col-lg-4 offset-lg-1 com-sm-12 md_none">
            <div className="log_text_one">Simplify Unlock Efficiency and Elevate Your Real Estate Business with WOVNN</div>
            <div className="log_text_two">WOVNN</div>
            <div className="log_image">
              <img src={Arrow} alt="Arrow" />
            </div>
            <div className="bottom_text_one">Get Started Today for Seamless Property Management</div>
            <div className="bottom_multiple_text">
              <div className="b1">No card details required</div>
              <div className="b1 b2">Senior technical architect's assistance</div>
              <div className="b1 b3">Keep all the work secure</div>
            </div>
          </div>

          <div className="col-lg-4 offset-lg-2 onset-lg-1 com-sm-12">
            {/* sent-otp */}
            {currentScreen === "send-otp" && (
              <div className="loginRegister forgot_password">
                {error ? (
                  <div
                    style={{
                      textAlign: "center",
                      margin: "10px 0px",
                      background: "#FFCCCB",
                      color: "red",
                    }}
                    className="alert "
                    role="alert"
                  >
                    {error}
                  </div>
                ) : null}
                <div className="d-flex log_reg forgeet_only">
                  <button className="btn border-end px-4">Forgot Password</button>
                </div>
                <div className="peter_text">
                  <p>Enter your register email to get OTP</p>
                </div>
                <div className="forgot_fields">
                  <div className="mb-3">
                    <input value={forgotPasswordEmail} onChange={(e) => setForgotPasswordEmail(e.target.value)} type="email" className="form-control" placeholder="Email Address*" />
                  </div>
                  <button type="submit" className="logreg_btn btn btn-primary forget-btns" onClick={() => handleForgotPasswordSubmit()}>
                    Send OTP
                  </button>
                </div>

                <div className="login_again">
                  <Link to="/">Login</Link> with password!
                </div>
              </div>
            )}

            {/* get-otp */}
            {currentScreen === "get-otp" && (
              <div className="loginRegister forgot_password">
                {error ? (
                  <div
                    style={{
                      textAlign: "center",
                      margin: "10px 0px",
                      background: "#FFCCCB",
                      color: "red",
                    }}
                    className="alert "
                    role="alert"
                  >
                    {error}
                  </div>
                ) : null}
                <div className="d-flex log_reg forgeet_only">
                  <button className="btn border-end px-4">Get OTP</button>
                </div>
                <div className="peter_text">
                  <p>Write OTP to change the password, OTP sent on mail.</p>
                </div>
                <div className="forgot_fields">
                  <div className="mb-3">
                    <input type="text" className="form-control" placeholder="Verify OTP*" value={otp} onChange={(e) => setOtp(e.target.value)} />
                  </div>
                  <button onClick={() => handleVerifyOTPSubmit()} type="submit" className="logreg_btn btn btn-primary forget-btns">
                    Verify
                  </button>
                </div>
                <div className="login_again">
                  <Link to="/">Login</Link> with password!
                </div>
              </div>
            )}

            {/* Change-Password */}
            {currentScreen === "change-password" && (
              <div className="loginRegister forgot_password">
                <div className="d-flex log_reg forgeet_only">
                  <button className="btn border-end px-4">Change Password</button>
                </div>
                <div className="peter_text">
                  <p>Make sure to use a strong password.</p>
                </div>
                <div className="forgot_fields">
                  <div className="mb-3">
                    <input type="text" className="form-control" placeholder="New Password*" value={newPassword} onChange={(e) => setNewPassword(e.target.value)} />
                  </div>
                  <div className="mb-3">
                    <input type="text" className="form-control" placeholder=" Again New Password*" value={confirmNewPassword} onChange={(e) => setConfirmNewPassword(e.target.value)} />
                  </div>
                  <button type="submit" className="logreg_btn btn btn-primary forget-btns" onClick={handleResetPasswordSubmit}>
                    Change Password
                  </button>
                </div>
                <div className="login_again">
                  <Link to="/">Login</Link> with password!
                </div>
              </div>
            )}

            {/* Password-Changed-Successfully */}
            {currentScreen === "password-changed-successfully" && (
              <div className="loginRegister forgot_password final_changed">
                <div className="d-flex log_reg forgeet_only">
                  <button className="btn border-end px-4"> Change Password</button>
                </div>
                <p className="great_news">Great news! Your password has been successfully updated. If you have any concerns, reach out to us.</p>
                <div className="forgot_fields image_specific">
                  <img src={Yes} alt="Pasword-changed" />
                </div>
                <div className="ipdated_now">Your password is updated Now</div>
                <div className="l_btn">
                  <Link to="/">
                    <button type="submit" className="logreg_btn btn btn-primary forget-btns">
                      Login Now
                    </button>
                  </Link>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ForgotPassword;
