import React from "react";
import { Modal, Button } from "react-bootstrap";
import "./DeleteAlert.css";
import DeleteLarge from "../../assets/big-cross.svg";
import Cross from "../../assets/close.svg";

const DeleteAlert = ({ show, handleClose, handleDelete }) => {
  return (
    <Modal className="delete-modal" show={show} onHide={handleClose} centered>
      <Modal.Body>
        <div className="delete-modal-content">
          <div className="delete-modal-header">
            <img src={DeleteLarge} alt="" />
            <h5>Are you sure?</h5>
            <img src={Cross} alt="" className="modal_deklete" onClick={handleClose} />
          </div>
          <p>Do you really want to delete these records? This process cannot be undone.</p>
          <div className="delete-modal-actions">
            <Button variant="secondary" onClick={handleClose}>
              Cancel
            </Button>
            <Button variant="danger" onClick={handleDelete}>
              Delete
            </Button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default DeleteAlert;
