import React from "react";
import "./Chat.css";
import { Modal } from "react-bootstrap";
import Cross from "../../assets/close.svg";
import Company from "../../assets/c-logo.png";
import Send from "../../assets/send.png";

const Chat = ({ show, handleClose }) => {
  return (
    <Modal className="chat-modal" show={show} onHide={handleClose} centered backdrop="static" keyboard={false}>
      <Modal.Body>
        <div className="chat-modal-content">
          <div className="chat-modal-header">
            <div className="header-info">
              <div className="comp_info">
                <img src={Company} alt="Company Logo" className="company-logo" />
                <div className="reraa">
                  <div className="company-name">Premier Realty Group</div>
                  <div className="last-active">Last active at 01:20pm</div>
                </div>
              </div>
            </div>
            <img src={Cross} alt="Close" className="close-chat" onClick={handleClose} />
          </div>
          <div className="chat-messages">
            <div className="chat-message left">
              <div className="message-text">Can you help me find some options?</div>
              <div className="message-time">01:20 pm</div>
            </div>
            <div className="chat-message right">
              <div className="message-text">I'd be happy to assist you in your home search!</div>
              <div className="message-time">01:22 pm</div>
            </div>
            <div className="chat-message left">
              <div className="message-text">Great! I'll start searching for condos that match your criteria</div>
              <div className="message-time">01:25 pm</div>
            </div>
            <div className="chat-message right">
              <div className="message-text">Sure, my phone number is (555) 123-4567</div>
              <div className="message-time">01:32 pm</div>
            </div>

            <div className="latest_message">Today</div>

            <div className="chat-message left">
              <div className="message-text">Thank you. I'll get to work on this and will be in touch with property listings soon.</div>
              <div className="message-time">08:25 am</div>
            </div>
          </div>
          <div className="chat-input-area">
            <input type="text" placeholder="Write here..." className="chat-input" />
            <button className="send-button">
              <img src={Send} alt="Send" />
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default Chat;
