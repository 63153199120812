const initialState = {
  token: null,
  status: null,
  email: null,
  regEmail: null,
  regToken: null,
  refreshToken: null,
  tokenExpTime: null,
  profileImage: null,
};
const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_TOKEN":
      return {
        ...state,
        token: action.payload.token,
      };
    case "DEL_TOKEN":
      return {
        ...state,
        token: null,
      };

    case "SET_STATUS":
      return {
        ...state,
        status: action.payload.status,
      };
      case "SET_PROFILE":
        return {
          ...state,
          profileImage: action.payload.profileImage,
        };
    case "SET_EMAIL":
      return {
        ...state,
        email: action.payload.email,
      };
    case "SET_REGEMAIL":
      return {
        ...state,
        regEmail: action.payload.regEmail,
      };

    case "SET_REGTOKEN":
      return {
        ...state,
        regToken: action.payload.regToken,
      };
    
    case "SET_REFRESHTOKEN":
      return {
        ...state,
        refreshToken: action.payload.refreshToken,
      };

    case "SET_TOKEN_EXP_TIME":
      return {
        ...state,
        tokenExpTime: action.payload.tokenExpTime,
      };

    case "SET_CATEGORY":
      return {
        ...state,
        category: action.payload.category,
      };
    case "DEL_PASSWORD":
      return {
        ...state,
        password: null,
      };
      case "SET_LOGOUT":
      return {
        ...state,
        regToken: null,
      };
    default:
      return state;
  }
};
export default authReducer;
