import React, { useState, useEffect } from "react";
import "./Realtor.css";
import axios from "axios";
import Url from "../Element/Api";
import useAuth from "../../hooks/useAuth";
import { getInitials } from "../../utils/utils";
import ClipLoader from "react-spinners/ClipLoader";
import BrokerActivityLog from "./BrokerActivityLog";
import Verified from "../../assets/verified-logo.svg";
import BrokerProfileHeader from "./BrokerProfileHeader";
import { Link, useNavigate, useParams } from "react-router-dom";
import BrokerTransactionSection from "./BrokerTransactionSection";
import Header from "../../components/DashboardArea/Header/Header";
import Sidebar from "../../components/DashboardArea/Sidebar/Sidebar";
import PendingProfileModal from "../ModalMessage/PendingProfileModal";

const Profile = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [config] = useAuth();
  const [teamMates, setTeamMates] = useState([]);
  const [profileLoading, setProfileLoading] = useState(true);
  const [realtorLoading, setRealtorLoading] = useState(true);
  const [isPendingPopupVisible, setIsPendingPopupVisible] = useState(false);
  const [brokerData, setBrokerData] = useState({
    data: {
      firstname: "",
      lastname: "",
      profileTagline: "",
      status: "",
      bio: "",
      phone: "",
      contactDetails: {
        address: "",
        license: "",
      },
      email: "",
      isVerified: "",
      serviceAreas: "",
      image: "",
    },
  });

  useEffect(() => {
    axios
      .get(Url.BASEURL + `/admin/realtors-under-broker/${id}`, config)
      .then((value) => {
        setTeamMates(value.data.realtors);
        setRealtorLoading(false);
      })
      .catch((err) => {
        setRealtorLoading(false);
      })
      .finally(() => {
        setRealtorLoading(false);
      });
  }, []);

  const handleClick = (status, id) => {
    if (status === 2) {
      setIsPendingPopupVisible(true);
    } else {
      navigate(`/realtor-profile/${id}`);
    }
  };

  const TeamMate = ({ id, name, sales, imgSrc, status }) => (
    <div className="teammate">
      {imgSrc ? <img src={imgSrc} alt={name} className="teammate-img" /> : <div className="teammate-initials pp_init">{getInitials(name)}</div>}
      <div className="teammate-info" role="button" onClick={() => handleClick(status, id)}>
        <h3 className="text-capitalize">{name}</h3>
        <Link className="sales-link">{sales} Realtor</Link>
      </div>
    </div>
  );

  const brokerId = localStorage.getItem("brokerId");
  const brokerName = localStorage.getItem("brokerName");
  const navigateFrom = localStorage.getItem("navigateFrom");

  const breadcrumbData = [{ path: navigateFrom === "dashboard" ? "/dashboard" : "/manage-broker", label: navigateFrom === "dashboard" ? "Dashboard" : "Manage Brokers" }, { label: brokerName }];

  return (
    <div className="dashboard-container">
      <div className="sidebar-main">
        <Sidebar />
      </div>
      <div className="header-dasboard main_container">
        <Header breadcrumbData={breadcrumbData} />
        <div className="dashboard">
          <div className="container-fluid main_container">
            <div className="row">
              <div className="col-lg-12 col-sm-12">
                <BrokerProfileHeader
                  onSetProfileLoading={(value) => setProfileLoading(value)}
                  onSetBrokerData={(value) => {
                    setBrokerData(value);
                  }}
                />
                <div className="row">
                  <div className="col-lg-9 jalos">
                    {profileLoading ? (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          height: "50vh",
                        }}
                      >
                        <ClipLoader color="#80C342" loading={profileLoading} size={150} aria-label="Loading Spinner" data-testid="loader" />
                      </div>
                    ) : (
                      <div className="mutual_blocks">
                        <div className="real_prop_flex d-flex">
                          <div className="real_image">{brokerData.image ? <img src={`${Url.IMAGE_URL}/${brokerData.image}`} alt="user" /> : <div className="teammate-initials">{getInitials(brokerData.firstname + " " + brokerData.lastname)}</div>}</div>
                          <div className="real_teaxing">
                            <div className="top_row_real d-flex">
                              <h2 className="text-capitalize">
                                {brokerData.firstname && brokerData.lastname ? brokerData.firstname + " " + brokerData.lastname : brokerData.firstname || "N/A"}
                                <span>{brokerData.profileTagline}</span>
                              </h2>
                              <div className="del_active d-flex">
                                <div className={`status_view ${brokerData.status ? "greener_btn" : "yalner"}`}>{brokerData.status ? "Active" : "Inactive"}</div>
                              </div>
                            </div>
                            <p>{brokerData.bio && brokerData.bio ? brokerData.bio : "N/A"}</p>
                          </div>
                        </div>

                        <div className="contact_realtor">
                          <h2>Contact Details</h2>

                          <div className="contact_row_flex d-flex">
                            <div className="left_real_contact cr1">{brokerData.phone ? brokerData.phone : "N/A"}</div>
                            <div className="right_real_contact cr2">{brokerData.contactDetails && brokerData.contactDetails.address ? brokerData.contactDetails.address : "N/A"}</div>
                          </div>

                          <div className="contact_row_flex d-flex">
                            <div className="left_real_contact cr3">{brokerData.email ? brokerData.email : "N/A"}</div>
                            <div className="right_real_contact cr4">
                              License : {brokerData.contactDetails && brokerData.contactDetails.license ? brokerData.contactDetails.license : "N/A"}{" "}
                              <span className="verification">
                                {brokerData.isVerified && <img src={Verified} alt="verified" />}
                                verified
                              </span>
                            </div>
                          </div>
                        </div>

                        <div className="contact_realtor ser_areas_realtor">
                          <h2>Service Area</h2>

                          <div className="area_listing_realtor d-flex">
                            {brokerData.serviceArea && brokerData.serviceArea.length > 0 ? (
                              brokerData.serviceArea.map((area, index) => (
                                <div className="list_con" key={index}>
                                  {area}
                                </div>
                              ))
                            ) : (
                              <div className="list_con">N/A</div>
                            )}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>

                  <div className="col-lg-3 col-sm-12 wid_set getter">
                    {realtorLoading ? (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          height: "50vh",
                        }}
                      >
                        <ClipLoader color="#80C342" loading={realtorLoading} size={150} aria-label="Loading Spinner" data-testid="loader" />
                      </div>
                    ) : (
                      <div className="mutual_blocks team_mamet mater">
                        <div className="heading_inline d-flex">
                          <div className="left_head">
                            <h2>Realtors</h2>
                          </div>
                          <div className="right_head">
                            <Link to={`/realtors-under-broker/${id}`} className="details-link">
                              View All
                            </Link>
                          </div>
                        </div>

                        <div className="teammate_list">
                          {teamMates.length === 0 ? (
                            <tr className="text-center">
                              <td
                                colSpan="9"
                                style={{
                                  fontSize: 16,
                                  marginTop: "10px",
                                  alignSelf: "center",
                                  textAlign: "center",
                                }}
                              >
                                No Realtors Found
                              </td>
                            </tr>
                          ) : (
                            teamMates.slice(0, 4).map((mate, idx) => (
                              <TeamMate
                                key={idx}
                                id={mate._id}
                                name={mate.firstname + " " + mate.lastname}
                                // sales={mate._id}
                                imgSrc={mate.image ? mate.imageURL : ""}
                                status={mate.status}
                              />
                            ))
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="row sattert-4">
              <BrokerActivityLog />
              <BrokerTransactionSection />
            </div>
          </div>
        </div>
      </div>

      <PendingProfileModal from="realtor" show={isPendingPopupVisible} handleClose={() => setIsPendingPopupVisible(false)} />
    </div>
  );
};

export default Profile;
