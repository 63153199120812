import React from "react";
import Pending from "../../assets/pending.gif";
import { Modal } from "react-bootstrap";

const PendingProfileModal = ({ show, handleClose, from }) => {
  return (
    <Modal className="delete-modal" show={show} onHide={handleClose} centered>
      <Modal.Body>
        <p className="mt-0" style={{ color: "#787D8C", fontSize: "18px", fontWeight: 500 }}>
          This {from === "broker" ? "broker's" : "realtor's"} profile is still pending.
        </p>
        <img src={Pending} alt="" height={"90px"} />
        <div className="two_tube_btns d-flex mt-4">
          <div className="ok_btn" onClick={handleClose}>
            OK
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default PendingProfileModal;
