import React from "react";

const TransactionsData = ({ allTransactions }) => {
  return (
    <tr>
      {/* Condition 1: No realtorName */}
      {!allTransactions.realtorName && (
        <>
          {/* <td>Broker SetUp Fees:</td> */}
          <td>
            MLS <span className="blacking">({allTransactions.selectedMlsCount})</span> : <span className="blacking">${allTransactions.selectedMlsPrice}</span> + Wovnn Fees : <span className="blacking">${allTransactions.idxMlsfee}</span>
            <br />
            Realtor Seats <span className="blacking">({allTransactions.totalRealtorcountunderBroker})</span> : <span className="blacking">${allTransactions.totalRealtorpriceunderBroker}</span>{" "}
          </td>
          <td>
            <div className="pamentse_link blacking">--</div>
          </td>
          <td>
            <div className="wovnn_amount">
              ${allTransactions.amount}
              <p className="text-capitalize">{allTransactions.brokerName}</p>
            </div>
          </td>
          <td className={allTransactions.status === "succeeded" ? "successess" : allTransactions.status === "Delinquent" ? "delquent" : allTransactions.status === "failed" ? "pendinggs" : ""}>
            <div className="status">
              {allTransactions.status === "Delinquent" ? <span className="qustion_mark">?</span> : ""}${allTransactions.wovnnFees}
              <br />
              <span className={allTransactions.status}>
                {allTransactions.status === "succeeded" ? "Successful" : allTransactions.status === "failed" ? "Failed" : ""}:{" "}
                <span className="date_set">
                  {new Date(allTransactions.date).toLocaleDateString("en-US", {
                    year: "numeric",
                    month: "2-digit",
                    day: "2-digit",
                  })}
                </span>
              </span>
            </div>
          </td>
        </>
      )}

      {/* Condition 2: RealtorName with setup fee */}
      {allTransactions.realtorName && allTransactions.feetype && (
        <>
          <td>
            Realtor SetUp Fees: <span className="blacking">${allTransactions.brokerSetUpFee}</span>
          </td>
          <td>
            <div className="pamentse_link">
              <div className="price_numberss">${allTransactions.amount}</div>
              <p className="text-capitalize">{allTransactions.realtorName}</p>
            </div>
          </td>
          <td>
            <div className="wovnn_amount">
              ${allTransactions.brokerFinalAmount}
              <p className="text-capitalize">{allTransactions.brokerName}</p>
            </div>
          </td>
          <td className={allTransactions.status === "succeeded" ? "successess" : allTransactions.status === "Delinquent" ? "delquent" : allTransactions.status === "Pending" ? "pendinggs" : ""}>
            <div className="status">
              {allTransactions.status === "Delinquent" ? <span className="qustion_mark">?</span> : ""}${allTransactions.wovnnCut}
              <br />
              <span className={allTransactions.status}>
                {allTransactions.status === "succeeded" ? "Successful" : allTransactions.status === "failed" ? "Failed" : ""}:{" "}
                <span className="date_set">
                  {new Date(allTransactions.date).toLocaleDateString("en-US", {
                    year: "numeric",
                    month: "2-digit",
                    day: "2-digit",
                  })}
                </span>
              </span>
            </div>
          </td>
        </>
      )}

      {/* Condition 3: RealtorName with no setup fee */}
      {allTransactions.realtorName && !allTransactions.feetype && (
        <>
          <td>
            Realtor Monthly Fees: <span className="blacking">${allTransactions.brokerFinalAmount}</span>
          </td>
          <td>
            <div className="pamentse_link">
              <div className="price_numberss">${allTransactions.amount}</div>
              <p className="text-capitalize">{allTransactions.realtorName}</p>
            </div>
          </td>
          <td>
            <div className="wovnn_amount">
              ${allTransactions.brokerFinalAmount}
              <p className="text-capitalize">{allTransactions.brokerName}</p>
            </div>
          </td>
          <td className={allTransactions.status === "succeeded" ? "successess" : allTransactions.status === "Delinquent" ? "delquent" : allTransactions.status === "Pending" ? "pendinggs" : ""}>
            <div className="status">
              {allTransactions.status === "Delinquent" ? <span className="qustion_mark">?</span> : ""}${allTransactions.wovnnCut}
              <br />
              <span className={allTransactions.status}>
                {allTransactions.status === "succeeded" ? "Successful" : allTransactions.status === "failed" ? "Failed" : ""}:{" "}
                <span className="date_set">
                  {new Date(allTransactions.date).toLocaleDateString("en-US", {
                    year: "numeric",
                    month: "2-digit",
                    day: "2-digit",
                  })}
                </span>
              </span>
            </div>
          </td>
        </>
      )}
    </tr>
  );
};

export default TransactionsData;
