import React, { useState } from "react";
import Sidebar from "../DashboardArea/Sidebar/Sidebar";
import Header from "../DashboardArea/Header/Header";
import Calender from "../../assets/calend.svg";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./Realtor.css";
import { LineChart, Line, AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from "recharts";
import { CustomizedXAxisTick, CustomizedYAxisTick } from "../../utils/utils";

const blockData = [
  {
    iconClass: "icon-vector4",
    number: 12345,
    text: "Revenue",
    chartData: [{ uv: 300 }, { uv: 400 }, { uv: 250 }, { uv: 310 }, { uv: 280 }, { uv: 225 }, { uv: 400 }],
    color: "#007AFF",
    blockClass: "block-color1",
  },
  {
    iconClass: "icon-vector0",
    number: 67890,
    text: "Sales in %",
    chartData: [{ uv: 200 }, { uv: 100 }, { uv: 200 }, { uv: 310 }, { uv: 20 }, { uv: 325 }, { uv: 800 }],
    color: "#FF9600",
    blockClass: "block-color2",
  },
  {
    iconClass: "icon-Vector1",
    number: 11121,
    text: "Sales",
    chartData: [{ uv: 300 }, { uv: 400 }, { uv: 250 }, { uv: 310 }, { uv: 280 }, { uv: 225 }, { uv: 1200 }],
    color: "#28AC47",
    blockClass: "block-color3",
  },
  {
    iconClass: "icon-Vector2",
    number: 31415,
    text: "Active Listings",
    chartData: [{ uv: 300 }, { uv: 500 }, { uv: 100 }, { uv: 600 }, { uv: 50 }, { uv: 100 }, { uv: 400 }],
    color: "#ED4949",
    blockClass: "block-color4",
  },
];

/* Activity log chart */
const data = [
  { month: "Jan 2023", medianList: 10000, medianSold: 200000 },
  { month: "Feb 2023", medianList: 200000, medianSold: 100000 },
  { month: "Mar 2023", medianList: 30000, medianSold: 1250 },
  { month: "Apr 2023", medianList: 502144, medianSold: 260000 },
  { month: "May 2023", medianList: 0, medianSold: 260000 },
  { month: "June 2023", medianList: 310000, medianSold: 521254 },
  { month: "July 2023", medianList: 310000, medianSold: 260000 },
  { month: "Aug 2023", medianList: 340000, medianSold: 290000 },
];

const RealtorProfileActivityLog = () => {
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [showStartDatePicker, setShowStartDatePicker] = useState(false);
  const [showEndDatePicker, setShowEndDatePicker] = useState(false);

  const CustomInput = ({ value, onClick }) => <img src={Calender} alt="Calendar" onClick={onClick} />;

  return (
    <div className="dashboard-container">
      <div className="sidebar-main">
        <Sidebar />
      </div>
      <div className="header-dasboard main_container">
        <Header />

        <div className="dashboard">
          <div className="container-fluid main_container">
            <div className="row crow">
              {blockData.map((block, index) => (
                <div className={`col-lg-3 col-sm-12 col-md-6 halfa ${block.blockClass}`} key={index}>
                  <div className="block">
                    <div className="icon-bg">
                      <span className={block.iconClass}></span>
                    </div>
                    <div className="chart-space">
                      <LineChart width={100} height={50} data={block.chartData}>
                        <Line type="monotone" dataKey="uv" stroke={block.color} strokeWidth={3} dot={false} />
                      </LineChart>
                      <p>{block.chartText}</p>
                    </div>
                    <div className="number">{index === 0 ? `$${block.number}` : index === 1 ? `${block.number}%` : block.number}</div>
                    <p className="blockp">{block.text}</p>
                  </div>
                </div>
              ))}
            </div>

            <div className="row ">
              <div className="col-lg-12 col-md-12 col-sm-12">
                <div className="mutual_blocks borrom-blocks">
                  <ResponsiveContainer width="100%" height={300}>
                    <AreaChart
                      data={data}
                      margin={{
                        top: 10,
                        right: 30,
                        left: 0,
                        bottom: 0,
                      }}
                    >
                      <CartesianGrid strokeWidth={0.5} vertical={false} stroke="#efefef" />
                      <XAxis dataKey="month" tick={<CustomizedXAxisTick />} />
                      <YAxis tick={<CustomizedYAxisTick />} domain={[0, 1000000]} />
                      <Tooltip />
                      <Area type="monotone" dataKey="medianSold" stackId="1" stroke="#80C342" fill="#80C342" fillOpacity={1} />
                      <Area type="monotone" dataKey="medianList" stackId="1" stroke="#B2D235" fill="#B2D235" fillOpacity={1} />
                    </AreaChart>
                  </ResponsiveContainer>

                  <div className="overall_m_date d-flex">
                    <div className="mean_median d-flex custom_activity_only">
                      <div className="left_mean">
                        <span></span> Median List Price
                      </div>
                      <div className="right_mean">
                        <span></span> Median Sold Price
                      </div>
                    </div>
                    <div className="all_dateses d-flex">
                      <div className="from_date d-flex">
                        <div className="calendar_icon">
                          <DatePicker
                            selected={startDate}
                            onChange={(date) => {
                              setStartDate(date);
                              setShowStartDatePicker(false);
                            }}
                            customInput={<CustomInput />}
                            withPortal
                            shouldCloseOnSelect
                            showMonthYearPicker
                            dateFormat="MMMM yyyy" // Change date format here
                          />
                          {showStartDatePicker && <button onClick={() => setShowStartDatePicker(false)}>Close</button>}
                        </div>
                        <div className="date_setee">
                          <h3>From Date</h3>
                          <p>{`${startDate.toLocaleString("default", { month: "long" })} ${startDate.getFullYear()}`}</p>
                        </div>
                      </div>

                      <div className="center_bdr"></div>

                      <div className="from_date todalist d-flex">
                        <div className="calendar_icon">
                          <DatePicker
                            selected={endDate}
                            onChange={(date) => {
                              setEndDate(date);
                              setShowEndDatePicker(false);
                            }}
                            customInput={<CustomInput />}
                            withPortal
                            shouldCloseOnSelect
                            showMonthYearPicker
                            dateFormat="MMMM yyyy"
                          />
                          {showEndDatePicker && <button onClick={() => setShowEndDatePicker(false)}>Close</button>}
                        </div>
                        <div className="date_setee">
                          <h3>To Date</h3>
                          <p>{`${endDate.toLocaleString("default", { month: "long" })} ${endDate.getFullYear()}`}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RealtorProfileActivityLog;
