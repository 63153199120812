import React from 'react';
import './BlogPages.css';

import Posts from './Posts/Posts';
import SingleBlog from './Posts/SingleBlog';
import ManageTags from './Posts/ManageTags';
import BlogDefaultSetting from './Posts/BlogDefaultSetting';



function BlogPages() {
    return  <div className="setting-pages">
   
   <Posts/>
   <SingleBlog/>
   <ManageTags/>
   <BlogDefaultSetting/>
  
</div>;
}

export default BlogPages;
