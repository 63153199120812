import React, { useState, useEffect } from "react";
import "./Dashboard.css";
import axios from "axios";
import Url from "../../Element/Api";
import { Link } from "react-router-dom";
import useAuth from "../../../hooks/useAuth";
import MlsImage from "../../../assets/mls-1.png";
import ClipLoader from "react-spinners/ClipLoader";
import BrokerListSection from "./BrokerListSection";
import { PieChart, Pie, Cell, Tooltip, ResponsiveContainer, BarChart, Bar, XAxis, YAxis, CartesianGrid } from "recharts";

const COLORS = ["#80C342", "#DEE7EE"];

const barData = [
  { name: "Feb 23", sales: 1000 },
  { name: "Mar 23", sales: 750 },
  { name: "Apr 23", sales: 500 },
  { name: "May 23", sales: 500 },
  { name: "Jun 23", sales: 1000 },
];

const Dashboard = () => {
  const [config] = useAuth();
  const [loading, setLoading] = useState(true);
  const [brokerLoading, setBrokerLoading] = useState(true);
  const [data, setData] = useState([]);
  const [totalSeats, setTotalSeats] = useState(0);
  const [percentage, setPercentage] = useState(0);
  const [mlsData, setMlsData] = useState([]);
  const [barWidth, setBarWidth] = useState(50);
  const [viewAll, setViewAll] = useState(false);
  const [newData, setNewData] = useState([]);

  useEffect(() => {
    const fetchData = () => {
      axios
        .get(`${Url.BASEURL}/admin/broker-realtors`, config)
        .then((res) => {
          setNewData(res.data.brokersWithRealtors);
          setBrokerLoading(false);
        })
        .catch((err) => {
          setBrokerLoading(false);
        })
        .finally(() => setBrokerLoading(false));
    };

    fetchData();
  }, []);

  // To get MLS listing
  useEffect(() => {
    const fetchMlsListings = () => {
      setLoading(true);
      axios
        .get(`${Url.BASEURL}/admin/get-all-mls-listings`, config)
        .then((res) => {
          setMlsData(res.data);
        })
        .catch((err) => {
          setLoading(false);
        })
        .finally(() => setLoading(false));
    };

    fetchMlsListings();
  }, []);

  /* bar chart */
  useEffect(() => {
    const updateBarWidth = () => {
      const screenWidth = window.innerWidth;
      if (screenWidth < 768) {
        setBarWidth(20);
      } else if (screenWidth >= 768 && screenWidth < 992) {
        setBarWidth(30);
      } else {
        setBarWidth(50);
      }
    };

    updateBarWidth();
    window.addEventListener("resize", updateBarWidth);
    return () => window.removeEventListener("resize", updateBarWidth);
  }, []);

  // To show the total seats Api
  useEffect(() => {
    const fetchTotalSeats = () => {
      axios
        .get(`${Url.BASEURL}/admin/brokers-realtors-count`, config)
        .then((res) => {
          const brokersCount = res.data.brokersCount;
          const realtorsCount = res.data.realtorsCount;
          const total = res.data.totalSeats;
          setData([
            { name: "Brokers", value: brokersCount },
            { name: "Realtors", value: realtorsCount },
          ]);
          setTotalSeats(total);
          const calculatedPercentage = ((brokersCount + realtorsCount) / total) * 100;
          setPercentage(calculatedPercentage);
        })
        .catch((err) => console.log("err", err));
    };

    fetchTotalSeats();
  }, []);

  return (
    <div className="dashboard">
      <div className="container-fluid main_container">
        <div className="row mattert-4">
          <div className="col-lg-12 col-md-12 col-sm-12">
            <div className="mutual_blocks borrom_blocks broker-section">
              <div className="board_row d-flex">
                <div className="left_dash_heading">Broker</div>
                <div className="right_dash_link" onClick={() => setViewAll(!viewAll)} style={{ cursor: "pointer" }}>
                  <Link to="/manage-broker" style={{ textDecoration: "none" }}>
                    View All
                  </Link>
                </div>
              </div>
              <BrokerListSection from={"dashboard"} newData={newData} loading={brokerLoading} />
            </div>
          </div>
        </div>
        <div className="row mattert-4">
          <div className="col-lg-3 col-md-6 col-sm-12 jjj">
            <div className="mutual_blocks borrom_blocks">
              <div className="board_row d-flex">
                <div className="left_dash_heading">Total Seats</div>
                <div className="right_dash_link"></div>
              </div>

              <div className="p_chart">
                <ResponsiveContainer width="100%" height="100%">
                  <PieChart>
                    <Pie data={[{ name: "Total Seats", value: totalSeats }]} cx="50%" cy="50%" innerRadius="60%" outerRadius="80%" fill="#82ca9d" paddingAngle={0} dataKey="value" strokeWidth={3}>
                      {data.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                      ))}
                    </Pie>
                    <Tooltip />
                  </PieChart>
                </ResponsiveContainer>
                <div className="seats_vacant">{totalSeats}</div>
              </div>

              <div className="chart_values">
                <h3>{totalSeats}</h3>
                <p className="martgin_srtting">Total Seats</p>
              </div>

              <div className="d-flex justify-content-between">
                {data?.map((el, idx) => (
                  <p key={idx}>
                    {el.name} - {el.value}
                  </p>
                ))}
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-6 col-sm-12 jjj ddd">
            <div className="mutual_blocks settter ">
              <div className="board_row d-flex">
                <div className="left_dash_heading">MLS Listings</div>
              </div>
              <div className="mls_looping clopping">
                {loading ? (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "50vh",
                    }}
                  >
                    <ClipLoader color="#80C342" loading={loading} size={150} aria-label="Loading Spinner" data-testid="loader" />
                  </div>
                ) : (
                  mlsData?.mlslistings?.map((mls) => (
                    <div key={mls.id} className="single_mls_detail d-flex">
                      <div className="left_mls_image">
                        <img src={MlsImage} alt="mls" />
                      </div>
                      <div className="right_mls_data">
                        <h2>{mls.mlsName}</h2>
                        <p>{mlsData.mlslistingsCount} Listings</p>
                      </div>
                    </div>
                  ))
                )}
              </div>
            </div>
          </div>

          <div className="col-lg-5 col-md-12 col-sm-12 jjj">
            <div className="mutual_blocks bar_charty ">
              <div className="board_row d-flex bar_chart_only">
                <div className="left_dash_heading">Market Analysis</div>
                <div className="right_dash_link"></div>
              </div>
              <div className="bar_chart">
                <ResponsiveContainer width="100%" height={300}>
                  <BarChart data={barData} margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
                    <CartesianGrid stroke="#efefef" vertical={false} />
                    <XAxis dataKey="name" />
                    <YAxis tickFormatter={(value) => `$${value}k`} />
                    <Tooltip formatter={(value) => [`$${value}k`]} />
                    <Bar dataKey="sales" fill="#80C342" barSize={barWidth} />
                  </BarChart>
                </ResponsiveContainer>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
