import React from "react";
import { Link } from "react-router-dom";
import { CustomizedXAxisTick, CustomizedYAxisTick } from "../../utils/utils";
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from "recharts";

const data = [
  { month: "Jan 2023", medianList: 10000, medianSold: 200000 },
  { month: "Feb 2023", medianList: 200000, medianSold: 100000 },
  { month: "Mar 2023", medianList: 30000, medianSold: 1250 },
  { month: "Apr 2023", medianList: 502144, medianSold: 260000 },
  { month: "May 2023", medianList: 0, medianSold: 260000 },
  { month: "June 2023", medianList: 310000, medianSold: 521254 },
  { month: "July 2023", medianList: 310000, medianSold: 260000 },
  { month: "Aug 2023", medianList: 340000, medianSold: 290000 },
];

const BrokerActivityLog = () => {
  return (
    <div className="col-lg-7 col-md-12 col-sm-12 peepnes">
      <div className="mutual_blocks borrom-blocks">
        <div className="heading_inline d-flex">
          <div className="left_head">
            <h2>Activity Log</h2>
          </div>
          <div className="right_head">
            <Link to="#" className="details-link">
              More Details
            </Link>
          </div>
        </div>

        <ResponsiveContainer width="100%" height={300}>
          <AreaChart
            data={data}
            margin={{
              top: 10,
              right: 30,
              left: 0,
              bottom: 0,
            }}
          >
            <CartesianGrid strokeWidth={0.5} vertical={false} stroke="#efefef" />
            <XAxis dataKey="month" tick={<CustomizedXAxisTick />} />
            <YAxis tick={<CustomizedYAxisTick />} domain={[0, 1000000]} />
            <Tooltip />
            <Area type="monotone" dataKey="medianSold" stackId="1" stroke="#007AFF" fill="#007AFF" fillOpacity={1} />
            <Area type="monotone" dataKey="medianList" stackId="1" stroke="#22A9E0" fill="#22A9E0" fillOpacity={1} />
          </AreaChart>
        </ResponsiveContainer>
        <div className="mean_median d-flex">
          <div className="left_mean">
            <span></span> Median List Price
          </div>
          <div className="right_mean">
            <span></span> Median Sold Price
          </div>
        </div>
      </div>
    </div>
  );
};

export default BrokerActivityLog;
