import React, { useState } from "react";
import axios from "axios";
import Url from "../../Element/Api";
import useAuth from "../../../hooks/useAuth";
import Close from "../../../assets/close.svg";
import Verified from "../../../assets/verified.svg";
import Slide from "../../../assets/arrow_slide.svg";
import Delete from "../../../assets/Recycle Bin.svg";

const AddRealtorModal = ({ isFormVisible, onSetIsFormVisible }) => {
  const [config] = useAuth();
  const [error, setError] = useState("");
  const [slides, setSlides] = useState([{}]);
  const [realtors, setRealtors] = useState([]);
  const [disable, setDisable] = useState(false);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [userData, setUserData] = useState({
    Firstname: "",
    Lastname: "",
    Email: "",
    PhoneNo: "",
  });

  const nextSlide = () => {
    setError("");
    setCurrentSlide((current) => (current + 1) % slides.length);
  };

  const prevSlide = () => {
    setError("");
    setCurrentSlide((current) => (current === 0 ? slides.length - 1 : current - 1));
  };

  const deleteSlide = (index) => {
    if (index === 0) return;
    const newSlides = slides.filter((_, slideIndex) => slideIndex !== index);
    setSlides(newSlides);
    if (currentSlide >= newSlides.length) {
      setCurrentSlide(newSlides.length - 1);
    }
  };

  const closeModal = () => {
    setUserData({
      Firstname: "",
      Lastname: "",
      Email: "",
      PhoneNo: "",
    });
    slides.map((slide) => {
      slide.Firstname = "";
      slide.Lastname = "";
      slide.Email = "";
      slide.PhoneNo = "";
    });
  };

  const handleAddMultipleRealtorSendAPi = () => {
    setDisable(true);
    setRealtors((prevRealtors) => {
      const newRealtors = [...prevRealtors, { ...userData }];
      handleSendClick(newRealtors);
      return newRealtors;
    });
  };

  const handleInputChange = (e, field, index) => {
    setSlides((prevSlides) => {
      const newSlides = [...prevSlides];
      newSlides[index] = {
        ...newSlides[index],
        [field]: e.target.value,
      };
      return newSlides;
    });
  };

  const handleAddMultipleRealtor = () => {
    setRealtors((prevRealtors) => {
      const newRealtors = [...prevRealtors, { ...userData }];
      return newRealtors;
    });
    setUserData({
      Firstname: "",
      Lastname: "",
      Email: "",
      PhoneNo: "",
    });
  };

  const addSlide = () => {
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    const currentSlideData = slides[currentSlide];

    if (!currentSlideData.Email || !currentSlideData.Firstname || !currentSlideData.Lastname || !currentSlideData.PhoneNo) {
      setError("Please fill all details for the current slide");
    } else if (!emailRegex.test(currentSlideData.Email)) {
      setError("Please enter a valid email");
    } else {
      setError("");
      setCurrentSlide((prevSlide) => prevSlide + 1);
      setSlides((prevSlides) => [
        ...prevSlides,
        {
          Firstname: "",
          Lastname: "",
          Email: "",
          PhoneNo: "",
        },
      ]);
    }
  };

  const handleSendClick = async (updatedRealtors) => {
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    const currentSlideData = slides[currentSlide];

    if (!currentSlideData.Email || !currentSlideData.Firstname || !currentSlideData.Lastname || !currentSlideData.PhoneNo) {
      setError("Please fill all details for the current slide");
    } else if (!emailRegex.test(currentSlideData.Email)) {
      setError("Please enter a valid email");
    } else {
      setError("");
      const params = {
        realtors: slides,
      };
      await axios
        .post(Url.BASEURL + `/broker/invite-realtor`, params, config)
        .then((value) => {
          if (value) {
            onSetIsFormVisible(false);
            setUserData({
              Firstname: "",
              Lastname: "",
              Email: "",
              PhoneNo: "",
            });
          }
          setDisable(false);
        })
        .catch((err) => {
          setDisable(false);
        });
    }
  };

  return (
    <div className="modal fade realtor_modal" id="addRealtorModal" tabIndex="-1" aria-labelledby="addRealtorModalLabel" aria-hidden="true">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-body">
            {isFormVisible ? (
              <div className="title_bar_setting tag_add_new slider_serting">
                <div className="slades">
                  {slides.map((slide, index) => (
                    <div key={index} className={currentSlide === index ? "slides active" : "slides"}>
                      <div>
                        <div className="your-component">
                          <div className="realtor-form">
                            <div className="real-row d-flex">
                              <div className="real-left">
                                <div className="form-group">
                                  <input type="text" value={slide.Firstname} name="Firstname" className="form-control" placeholder="First Name*" onChange={(e) => handleInputChange(e, "Firstname", index)} />
                                </div>
                              </div>
                              <div className="real-right">
                                <div className="form-group">
                                  <input type="text" value={slide.Lastname} name="Lastname" className="form-control" placeholder="Last Name*" onChange={(e) => handleInputChange(e, "Lastname", index)} />
                                </div>
                              </div>
                            </div>
                            <div className="real-full-width">
                              <div className="form-group">
                                <input type="text" value={slide.Email} name="Email" className="form-control" placeholder="Email Address*" onChange={(e) => handleInputChange(e, "Email", index)} />
                              </div>
                            </div>
                            <div className="real-full-width">
                              <div className="form-group">
                                <input value={slide.PhoneNo} name="PhoneNo" type="text" className="form-control" placeholder="Phone No." onChange={(e) => handleInputChange(e, "PhoneNo", index)} />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>

                <button
                  className="add_multiple_realt"
                  onClick={() => {
                    addSlide();
                    handleAddMultipleRealtor();
                  }}
                >
                  + Add multiple Realtor
                </button>
                <div style={{ color: "red" }}>{error}</div>

                <div className="two_btns raltor_btns d-flex">
                  <button
                    className="btn update_btn saver raltor_btnss"
                    onClick={() => {
                      handleAddMultipleRealtorSendAPi();
                    }}
                    disabled={disable}
                  >
                    Send
                  </button>
                  <button className="btn cancel_btn raltor_btnss" data-bs-dismiss="modal" onClick={closeModal}>
                    Cancel
                  </button>
                  {slides.length > 1 && (
                    <button className="btn delete_realtor" onClick={() => deleteSlide(currentSlide)}>
                      <img src={Delete} alt="delete" />
                    </button>
                  )}
                </div>

                <div className="dotting">{slides.length > 1 && slides.map((_, index) => <div key={index} className={currentSlide === index ? "dot_list active" : "dot_list"} onClick={() => setCurrentSlide(index)}></div>)}</div>

                {slides.length > 1 && (
                  <div className="slides_arrows">
                    <div className="previous_aro" onClick={prevSlide}>
                      <img src={Slide} className="close" alt="" />
                    </div>
                    <div className="next_aro" onClick={nextSlide}>
                      <img src={Slide} className="close" alt="" />
                    </div>
                  </div>
                )}
              </div>
            ) : (
              <div className=" verification_done add_realtor_message emails_verification">
                <div className="cross_btn faloos" data-bs-toggle="modal" data-bs-target="#addRealtorModal">
                  <img src={Close} className="close" alt="" />
                </div>
                <h3>Invitation Sent</h3>
                <p>
                  You've successfully extended an invitation to{" "}
                  <div className="emailer_name">
                    <strong>{userData.Email}</strong>
                  </div>{" "}
                </p>
                <img src={Verified} alt="verified" />
                <h4>
                  Await their response and welcome them to your real <br></br>estate network.
                </h4>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddRealtorModal;
