import React, { useState, useEffect, useRef } from "react";
import "./Realtor.css";
import axios from "axios";
import Url from "../Element/Api";
import useAuth from "../../hooks/useAuth";
import { LineChart, Line } from "recharts";
import { useParams } from "react-router-dom";
import { getInitials, handleDropdownClose } from "../../utils/utils";
import ModalMessage from "../ModalMessage/Modal";
import Arrow from "../../assets/upper-arrow.svg";
import ClipLoader from "react-spinners/ClipLoader";
import Verified from "../../assets/verified-logo.svg";
import Header from "../../components/DashboardArea/Header/Header";
import RealtorActivityLogs from "./components/RealtorActivityLogs";
import RealtorTransactions from "./components/RealtorTransactions";
import Sidebar from "../../components/DashboardArea/Sidebar/Sidebar";

const blockData = [
  {
    iconClass: "icon-vector4",
    number: 0,
    text: "",
    chartData: [{ uv: 300 }, { uv: 400 }, { uv: 250 }, { uv: 310 }, { uv: 280 }, { uv: 225 }, { uv: 400 }],
    color: "#007AFF",
    blockClass: "block-color1",
  },
  {
    iconClass: "icon-vector0",
    number: 0,
    text: "Leads",
    chartData: [{ uv: 200 }, { uv: 100 }, { uv: 200 }, { uv: 310 }, { uv: 20 }, { uv: 325 }, { uv: 800 }],
    color: "#FF9600",
    blockClass: "block-color2",
  },
  {
    iconClass: "icon-Vector1",
    number: 0,
    text: "Total Transactions",
    chartData: [{ uv: 300 }, { uv: 400 }, { uv: 250 }, { uv: 310 }, { uv: 280 }, { uv: 225 }, { uv: 1200 }],
    color: "#28AC47",
    blockClass: "block-color3",
  },
  {
    iconClass: "icon-Vector2",
    number: 0,
    text: "Active Listings",
    chartData: [{ uv: 300 }, { uv: 500 }, { uv: 100 }, { uv: 600 }, { uv: 50 }, { uv: 100 }, { uv: 400 }],
    color: "#ED4949",
    blockClass: "block-color4",
  },
  {
    isDifferent: true,
  },
];

const options = ["Active", "Inactive"];

const Profile = () => {
  const { id } = useParams();
  const [config] = useAuth();
  const dropdownRef = useRef(null);
  const [modalType, setModalType] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [realtorData, setRealtorData] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [profileLoading, setProfileLoading] = useState(true);
  const [activeListings, setActiveListings] = useState(0);
  const [transactionCount, setTransactionCount] = useState(0);
  const [leadsCount, setLeadsCount] = useState(0);
  const [totalPaidAmount, setTotalPaidAmount] = useState(0);
  const [realtorword, setRealtorword] = useState(0);
  const [disable, setDisable] = useState(false);

  const updatedBlockData = [...blockData];
  updatedBlockData[3].number = activeListings;
  updatedBlockData[2].number = transactionCount;
  updatedBlockData[1].number = leadsCount;
  updatedBlockData[0].number = totalPaidAmount.toFixed(2);
  updatedBlockData[0].text = realtorword;

  const onOptionClicked = (value) => {
    if (value === "Active" || value === "Inactive") {
      setModalType(value.toLowerCase());
      setShowModal(true);
    } else {
      setSelectedOption(value);
      setIsOpen(false);
    }
  };

  const fetchRealtorUnderAdmin = async () => {
    setProfileLoading(true);
    axios
      .get(Url.BASEURL + `/admin/get-realtor/${id}`, config)
      .then((value) => {
        setRealtorData(value.data.realtor);
        setActiveListings(value.data.propertyCount);
        setTransactionCount(value.data.transactionCount);
        setLeadsCount(value.data.leadsCount);
        setTotalPaidAmount(value.data.totalPaidAmount);
        setRealtorword(value.data.realtorword);
        setProfileLoading(false);
      })
      .catch((err) => {
        setProfileLoading(false);
      })
      .finally(() => {
        setProfileLoading(false);
      });
  };

  useEffect(() => {
    fetchRealtorUnderAdmin();
  }, [id, selectedOption]);

  const handleModalButtonClick = async () => {
    const newStatus = modalType === "active" ? "Active" : "Inactive";
    const status = modalType === "active" ? true : false;
    setDisable(true);
    axios
      .post(Url.BASEURL + `/admin/change-realtor-status`, { realtorId: id, status: status }, config)
      .then((res) => {
        fetchRealtorUnderAdmin();
        setSelectedOption(newStatus);
        setShowModal(false);
        setIsOpen(false);
        setModalType(null);
        setDisable(false);
      })
      .catch((error) => {
        setDisable(false);
        console.error("Error on Updating status:", error);
      });
  };

  useEffect(() => {
    const handleClose = () => {
      setIsOpen(false);
    };
    handleDropdownClose(dropdownRef, handleClose);
  }, [dropdownRef]);

  const brokerId = localStorage.getItem("brokerId");
  const brokerName = localStorage.getItem("brokerName");
  const navigateFrom = localStorage.getItem("navigateFrom");

  const breadcrumbData = [{ path: navigateFrom === "dashboard" ? "/dashboard" : "/manage-broker", label: navigateFrom === "dashboard" ? "Dashboard" : "Manage Brokers" }, { path: `/profile/${brokerId}`, label: brokerName }, { path: `/realtors-under-broker/${brokerId}`, label: "Realtors" }, { label: profileLoading ? "" : realtorData?.firstname + " " + realtorData?.lastname }];

  return (
    <div className="dashboard-container">
      <div className="sidebar-main">
        <Sidebar />
      </div>
      <div className="header-dasboard main_container">
        <Header breadcrumbData={breadcrumbData} />
        <div className="dashboard">
          <div className="container-fluid main_container">
            <div className="row">
              <div className="col-lg-12 col-sm-12">
                <div className="main_flexing ">
                  <div className="sub_flexing d-flex">
                    {blockData.map((block, index) =>
                      block.isDifferent ? (
                        <div className={`custom_structure ${block.blockClass}`} key={index}>
                          <div className="new-block">
                            <h2>Realtor Status</h2>

                            <div className="custom-dropdown" ref={dropdownRef}>
                              <div className="dropdown-header" onClick={() => setIsOpen(!isOpen)}>
                                {realtorData.status == 1 ? "Active" : realtorData.status == 0 ? "Inactive" : selectedOption || "Select an option"}
                                <img src={Arrow} alt="Dropdown Arrow" className="dropdown-arrow" onChange={(e) => setSelectedOption(e.target.value)} />
                              </div>
                              {isOpen && (
                                <div className="dropdown-list-container">
                                  <ul className="dropdown-list">
                                    {options.map((option) => (
                                      <li onClick={() => onOptionClicked(option)} key={Math.random()}>
                                        {option}
                                      </li>
                                    ))}
                                  </ul>
                                </div>
                              )}
                            </div>
                          </div>

                          <ModalMessage
                            show={showModal}
                            leftButton="Cancel"
                            rightButton={modalType === "active" ? "Active" : "Inactive"}
                            handleClose={() => {
                              setShowModal(false);
                              setDisable(false);
                            }}
                            disable={disable}
                            handleSave={handleModalButtonClick}
                            message={`Do you really want to ${modalType === "active" ? "Active" : "Inactivate"} this broker? This process can change anytime.`}
                          />
                        </div>
                      ) : (
                        <div className={` ${block.blockClass} line-charts-data`} key={index}>
                          <div className="block">
                            <div className="icon-bg">
                              <span className={block.iconClass}></span>
                            </div>
                            <div className="chart-space">
                              <LineChart width={100} height={50} data={block.chartData}>
                                <Line type="monotone" dataKey="uv" stroke={block.color} strokeWidth={3} dot={false} />
                              </LineChart>
                              <p>{block.chartText}</p>
                            </div>
                            <div className="number">{index === 0 ? `$${block.number}` : index === 1 ? `${block.number}` : block.number}</div>
                            <p className="blockp">{block.text}</p>
                          </div>
                        </div>
                      )
                    )}
                  </div>
                </div>

                <div className="row">
                  <div className="col-lg-12 jalos">
                    {profileLoading ? (
                      <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "50vh" }}>
                        <ClipLoader color="#80C342" loading={profileLoading} size={150} aria-label="Loading Spinner" data-testid="loader" />
                      </div>
                    ) : (
                      <div className="mutual_blocks">
                        <div className="real_prop_flex d-flex">
                          <div className="real_image">{realtorData.image ? <img src={realtorData.imageURL} alt="user" /> : <div className="teammate-initials">{getInitials(realtorData.firstname + " " + realtorData.lastname)}</div>}</div>
                          <div className="real_teaxing">
                            <div className="top_row_real d-flex">
                              <h2 className="text-capitalize">
                                {realtorData.firstname && realtorData.lastname ? realtorData.firstname + " " + realtorData.lastname : realtorData.firstname || "N/A"}
                                <span>{realtorData.profileTagline}</span>
                              </h2>
                              <div className="del_active d-flex">
                                {/* <div className="delete_icon">
                                    <img src={Delete} alt="user" />
                                  </div> */}
                                <div className={`status_view ${realtorData.status ? "greener_btn" : "yalner"}`}>{realtorData.status ? "Active" : "Inactive"}</div>
                              </div>
                            </div>
                            <p>{realtorData.bio && realtorData.bio ? realtorData.bio : "N/A"}</p>
                          </div>
                        </div>

                        <div className="contact_realtor">
                          <h2>Contact Details</h2>

                          <div className="contact_row_flex d-flex">
                            <div className="left_real_contact cr1">{realtorData.phone ? realtorData.phone : "N/A"}</div>
                            <div className="right_real_contact cr2">{realtorData.contactDetails && realtorData.contactDetails.address ? realtorData.contactDetails.address : "N/A"}</div>
                          </div>

                          <div className="contact_row_flex d-flex">
                            <div className="left_real_contact cr3">{realtorData.email ? realtorData.email : "N/A"}</div>
                            <div className="right_real_contact cr4">
                              License : {realtorData.contactDetails && realtorData.contactDetails.license ? realtorData.contactDetails.license : "N/A"}{" "}
                              <span className="verification">
                                {realtorData.isVerified && <img src={Verified} alt="verified" />}
                                verified
                              </span>
                            </div>
                          </div>
                        </div>

                        <div className="contact_realtor ser_areas_realtor">
                          <h2>Service Area</h2>

                          <div className="area_listing_realtor d-flex">
                            {realtorData.serviceArea && realtorData.serviceArea.length > 0 ? (
                              realtorData.serviceArea.map((area, index) => (
                                <div className="list_con" key={index}>
                                  {area}
                                </div>
                              ))
                            ) : (
                              <div className="list_con">N/A</div>
                            )}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="row sattert-4">
              <RealtorActivityLogs />
              <RealtorTransactions onSetSelectedOption={(val) => setSelectedOption(val)} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
