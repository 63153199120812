export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const setToken = (token) => ({
  type: "SET_TOKEN",
  payload: {
    token,
  },
});
export const delToken = (token) => ({
  type: "DEL_TOKEN",
});
export const setEmail = (email) => ({
  type: "SET_EMAIL",
  payload: {
    email,
  },
});
export const setRegEmail = (regEmail) => ({
  type: "SET_REGEMAIL",
  payload: {
    regEmail,
  },
});

export const setRegToken = (regToken) => ({
  type: "SET_REGTOKEN",
  payload: {
    regToken,
  },
});

export const setRefreshToken = (refreshToken) => ({
  type: "SET_REFRESHTOKEN",
  payload: {
    refreshToken,
  },
});

export const setTokenExpTime = (tokenExpTime) => ({
  type: "SET_TOKEN_EXP_TIME",
  payload: {
    tokenExpTime,
  },
});

export const setStatus = (status) => ({
  type: "SET_STATUS",
  payload: {
    status,
  },
});
export const setParentCategory = (category) => ({
  type: "SET_CATEGORY",
  payload: {
    category,
  },
});
export const delPassword = (password) => ({
  type: "DEL_PASSWORD",
});
export const setProfileImage = (profileImage) => ({
  type: "SET_PROFILE",
  payload: {
    profileImage,
  },
});

export const setLogOut = () => ({
  type: "SET_LOGOUT",
});