import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import ReactPaginate from "react-paginate";
import "./Listings.css";
import Sliders from "../../../assets/s3.svg";
import Url from "../../Element/Api";
import Eye from "../../../assets/eye.svg";
import Eyeslash from "../../../assets/eye-slash.svg";
import ModalMessage from "../../ModalMessage/Modal";
import ClipLoader from "react-spinners/ClipLoader";
import { NextLabel, PreviousLabel, getInitials, handleDropdownClose } from "../../../utils/utils";
import BaseLayout from "../../../layouts/BaseLayout";
import useAuth from "../../../hooks/useAuth";

const AgentdropdownOptions = ["All", "Active", "Inactive", "Latest Posted", "Oldest Posted", "Latest Updated", "Oldest Updated", "Price (Low to High)", "Price (High to Low)"];

function Listings() {
  const [config] = useAuth();
  const dropdownRef = useRef(null);
  const [mlsData, setMlsData] = useState([]);
  const [totalMlsData, setTotalMlsData] = useState(0);
  const [totalPage, setTotalPage] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [mlsId, setMlsId] = useState(null);
  const [propertyStatus, setPropertyStatus] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [selectedOption, setSelectedOption] = useState("All");
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  const itemsPerPage = 12;
  const currentPageAdjusted = Math.max(currentPage, 1);
  const indexOfFirstItem = (currentPageAdjusted - 1) * itemsPerPage + 1;
  const indexOfLastItem = Math.min(currentPageAdjusted * itemsPerPage, totalMlsData);
  const pageNumbers = [];

  for (let i = 1; i <= totalPage; i++) {
    pageNumbers.push(i);
  }

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      let queryParams = {
        page: currentPage,
        search: searchTerm,
      };

      if (selectedOption !== "All") {
        if (selectedOption === "Active" || selectedOption === "Inactive") {
          queryParams.status = selectedOption;
        } else if (selectedOption === "Price (Low to High)" || selectedOption === "Price (High to Low)") {
          queryParams.sortPrice = selectedOption === "Price (Low to High)" ? "lowToHigh" : "highToLow";
        } else if (AgentdropdownOptions.includes(selectedOption)) {
          queryParams.sortDate = selectedOption === "Latest Posted" ? "latestPosted" : selectedOption === "Oldest Posted" ? "oldestPosted" : selectedOption === "Latest Updated" ? "latestUpdated" : "oldestUpdated";
        }
      }

      try {
        const response = await axios.get(Url.BASEURL + `/mls/reso/global-search-result`, {
          params: queryParams,
          headers: config.headers,
        });

        setMlsData(response.data.properties.docs);
        setTotalMlsData(response.data.properties.total);
        setTotalPage(response.data.properties.pages);
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    };

    fetchData();
  }, [currentPage, searchTerm, selectedOption]);

  const handleEyeButtonClick = (mlsId, propertyStatus) => {
    setMlsId(mlsId);
    setPropertyStatus(propertyStatus);
    setShowModal(true);
  };

  const handleSave = async () => {
    try {
      const updatedStatus = !propertyStatus;
      await axios.put(Url.BASEURL + `/mls/reso/update-status/${mlsId}`, { status: updatedStatus }, config);
      setMlsData((prevMlsData) => prevMlsData.map((mls) => (mls._id === mlsId ? { ...mls, PropertyStatus: updatedStatus } : mls)));
      setShowModal(false);
    } catch (error) {
      setShowModal(false);
    }
  };

  const handleOptionSelect = (option) => {
    setSelectedOption(option);
    setIsDropdownOpen(false);
  };

  const PostsDropdown = () => {
    if (!isDropdownOpen) {
      setIsDropdownOpen(true);
    }
  };

  useEffect(() => {
    const handleClose = () => {
      setIsDropdownOpen(false);
    };
    handleDropdownClose(dropdownRef, handleClose);
  }, [dropdownRef]);

  const ListData = ({ mls }) => {
    return (
      <tr>
        <td>{mls?.UnparsedAddress}</td>
        <td className=" paper">
          {new Date(mls?.OnMarketDate).toLocaleDateString("en-US", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
          })}
          <span>&nbsp;&nbsp; - &nbsp;&nbsp;</span>
          {new Date(mls?.PropertyRecordUpdateTimestamp).toLocaleDateString("en-US", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
          })}
        </td>

        <td className="author-tags">
          {mls?.Member?.length > 0 ? (
            mls?.Member?.map((member, index) => (
              <div key={index} className="teammate-initials listing_initials hallowe">
                {getInitials(member?.MemberFullName)}
              </div>
            ))
          ) : (
            <div className="d-block" style={{ color: "black" }}>
              N/A
            </div>
          )}
        </td>
        <td className="font_set">
          <div className="d-block">{"$" + parseFloat(mls?.CurrentPriceForStatus?.$numberDecimal).toLocaleString("en-US")}</div>
        </td>
        <td className="font_set">
          <div role="button" className="d-block" onClick={() => handleEyeButtonClick(mls?._id, mls?.PropertyStatus)}>
            <div className="eye-button">
              <img src={mls?.PropertyStatus ? Eye : Eyeslash} alt="toggle visibility" />
            </div>
          </div>
        </td>
      </tr>
    );
  };

  const breadcrumbData = [{ label: "Listing" }];

  return (
    <BaseLayout breadcrumbData={breadcrumbData}>
      <div className="mutual_blocks amp-pages">
        <h2>Listing</h2>
        <div className="posting_area">
          <div className="row mb-3 padding">
            <div className="col-md-6 col-sm-12 d-flex justify-content-start align-items-center link_sett">
              <div className="results_realtor transactions_listing">{`${indexOfFirstItem}-${indexOfLastItem} of ${totalMlsData} Results`}</div>
            </div>
            <div className="col-md-6 col-sm-12 d-flex justify-content-end mku">
              <div className="search_area d-flex farea">
                <div className="search_bloxk">
                  <input
                    type="search"
                    className="form-control me-2"
                    placeholder="Search by Property Direction"
                    value={searchTerm}
                    onChange={(event) => {
                      setSearchTerm(event.target.value);
                    }}
                  />
                </div>
              </div>
              <div className="filter_area">
                <div className="agent_dropdown">
                  <div ref={dropdownRef} className={`drop_data ${isDropdownOpen ? "open" : ""}`}>
                    <div className="selected_option" onClick={PostsDropdown} style={{ backgroundImage: `url(${Sliders})` }}>
                      {selectedOption || "All"}
                    </div>
                    {isDropdownOpen && (
                      <div className="drop_selection">
                        {AgentdropdownOptions.map((option, index) => (
                          <p key={index} onClick={() => handleOptionSelect(option)}>
                            {option}
                          </p>
                        ))}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Table */}
          <div className="row">
            <div className="col-lg-12">
              {loading ? (
                <div className="text-center py-5">
                  <ClipLoader color="#80C342" loading={loading} size={150} aria-label="Loading Spinner" data-testid="loader" />
                </div>
              ) : (
                <div className="table-responsive tabhel">
                  <table className="table">
                    <thead>
                      <tr>
                        <th scope="col" style={{ width: "40%" }}>
                          Property Address
                        </th>
                        <th scope="col">
                          Published<span>&nbsp;&nbsp; - &nbsp;&nbsp;</span>Updated Date
                        </th>
                        <th scope="col">Local Realtor</th>
                        <th scope="col">Price</th>
                        <th scope="col">Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {mlsData.length > 0 ? (
                        mlsData.map((mls, index) => <ListData mls={mls} key={index} />)
                      ) : (
                        <tr className="text-center">
                          <td
                            colSpan="9"
                            style={{
                              fontSize: 16,
                              marginTop: "10px",
                              alignSelf: "center",
                              textAlign: "center",
                            }}
                          >
                            No data available
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              )}
              {totalPage > 1 && (
                <div className="all_pagination">
                  <ReactPaginate
                    previousLabel={<PreviousLabel />}
                    nextLabel={<NextLabel />}
                    breakLabel={"..."}
                    pageCount={totalPage}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    onPageChange={(e) => {
                      setCurrentPage(e.selected + 1);
                    }}
                    containerClassName={"pagination"}
                    activeClassName={"active"}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <ModalMessage
        show={showModal}
        message="Do you really want to change the listing status?"
        leftButton="Cancel"
        rightButton="Save"
        handleSave={handleSave}
        handleClose={() => {
          setShowModal(false);
        }}
      />
    </BaseLayout>
  );
}

export default Listings;
