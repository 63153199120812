import axios from "axios";
import React, { useEffect, useState } from "react";
import Url from "../Element/Api";
import { ClipLoader } from "react-spinners";
import TransactionModal from "./TransactionModal";
import { useParams } from "react-router-dom";
import useAuth from "../../hooks/useAuth";

const RealtordownOptions = [
  { type: "heading", content: "Payment" },
  { type: "option", content: "All", value: " " },
  { type: "option", content: "Successful", value: "succeeded" },
  { type: "option", content: "Failed", value: "failed" },
];

const BrokerTransactionSection = () => {
  const { id } = useParams();
  const [config] = useAuth();
  const [bgTransactions, setBgTransactions] = useState([]);
  const [bgTransactionsLoading, setBgTransactionsLoading] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const [selectedOptionRealtor, setSelectedOptionRealtor] = useState(" ");

  //Fetch background transactions
  useEffect(() => {
    const fetchTransactions = async () => {
      try {
        setBgTransactionsLoading(true);
        const queryValue = RealtordownOptions.find((item) => item.content === selectedOptionRealtor)?.value || "";
        const response = await axios.get(Url.BASEURL + `/admin/get-transactions-of-broker/${id}?page=${currentPage}&status=${queryValue}`, config);

        if (response.data.success) {
          setBgTransactions(response?.data?.allTransactions);
        } else {
          setBgTransactionsLoading(false);
        }
      } catch (error) {
        setBgTransactionsLoading(false);
      } finally {
        setBgTransactionsLoading(false);
      }
    };
    fetchTransactions();
  }, []);

  return (
    <div className="col-lg-5 col-md-12 col-sm-12">
      <div className="mutual_blocks ">
        <div className="heading_inline d-flex">
          <div className="left_head">
            <h2>Broker Transaction</h2>
          </div>
          <div className="right_head">
            <div className="details-link" data-bs-toggle="modal" data-bs-target="#addRealtorTransactionModal">
              View all
            </div>
          </div>
        </div>

        {bgTransactionsLoading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "50vh",
            }}
          >
            <ClipLoader color="#80C342" npm start loading={bgTransactionsLoading} size={150} aria-label="Loading Spinner" data-testid="loader" />
          </div>
        ) : (
          <div className="payment_type_status">
            <div className="table-responsive">
              <table>
                <thead>
                  <tr>
                    <th>Payment Type</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {bgTransactions.length === 0 ? (
                    <tr className="text-center">
                      <td
                        colSpan="9"
                        style={{
                          fontSize: 16,
                          marginTop: "10px",
                          alignSelf: "center",
                          textAlign: "center",
                        }}
                      >
                        No Transactions Found
                      </td>
                    </tr>
                  ) : (
                    bgTransactions.slice(0, 3).map((transaction, index) => (
                      <tr key={index}>
                        <td>
                          MLS <span className="blacking">{transaction.selectedMlsCount}</span> :<span className="blacking"> ${transaction.selectedMlsPrice}</span> + Wovnn Idx Fees : <span className="blacking">${transaction.idxMlsfee}</span>
                          <br />
                        
                          
                            <div>
                              Realtor Seats :{" "}
                              <span className="blacking">
                                {transaction.totalRealtorcountunderBroker} : ({transaction.totalRealtorpriceunderBroker})
                              </span>
                            </div>
                          
                         
                        </td>

                        <td>
                          <div className="status">
                            ${transaction.amount}
                            <br />
                            <span className="success">
                              {transaction.status === "succeeded" ? "Successful" : "Failed"}:{" "}
                              <span className="date_set">
                                {new Date(transaction.date).toLocaleDateString("en-US", {
                                  year: "numeric",
                                  month: "2-digit",
                                  day: "2-digit",
                                })}
                              </span>
                            </span>
                          </div>
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
            </div>
          </div>
        )}
      </div>

      <TransactionModal
        currentPage={currentPage}
        totalPages={totalPages}
        selectedOptionRealtor={selectedOptionRealtor}
        onSetCurrentPage={(value) => setCurrentPage(value)}
        onSetTotalPages={(value) => setTotalPages(value)}
        handleSelect={(option) => {
          setSelectedOptionRealtor(option);
        }}
      />
    </div>
  );
};

export default BrokerTransactionSection;
