import { Link } from "react-router-dom";
import LeftArrow from "../assets/larrow.svg";
import Arrow from "../assets/breads-aro.svg";

export const PreviousLabel = () => (
  <div className="laro">
    <img src={LeftArrow} alt="Previous page" />
    <span>Prev</span>
  </div>
);

export const NextLabel = () => (
  <div className="raro">
    <span>Next</span>
    <img src={LeftArrow} alt="Next page" />
  </div>
);

export const getInitials = (name) => {
  const parts = name.split(" ");
  if (parts.length > 1) {
    return `${parts[0][0].toUpperCase()}${parts[parts.length - 1][0].toUpperCase()}`;
  }
  return name[0].toUpperCase();
};

export const CustomizedXAxisTick = (props) => {
  const { x, y, payload } = props;
  return (
    <g transform={`translate(${x},${y})`}>
      <text x={0} y={0} dy={16} textAnchor="middle" fill="#B4B9C8" className="tick-label">
        {payload.value}
      </text>
    </g>
  );
};

export const CustomizedYAxisTick = (props) => {
  const { x, y, payload } = props;
  const formattedTick = `$${payload.value / 1000}K`;

  return (
    <g transform={`translate(${x},${y})`}>
      <text x={0} y={0} dx={-16} textAnchor="end" fill="#B4B9C8" className="tick-label">
        {formattedTick}
      </text>
    </g>
  );
};

export const Breadcrumbs = ({ crumbs }) => {
  if (crumbs.length === 0) {
    return null;
  }
  return (
    <ul className="breads">
      {crumbs.map((crumb, index) => {
        const isLast = index === crumbs.length - 1;
        return (
          <li key={index} className="breadcrumb-item">
            {index > 0 && <img src={Arrow} alt="Arrow" className="breadcrumb-arrow" />}
            {isLast ? crumb.label : <Link to={crumb.path}>{crumb.label}</Link>}
          </li>
        );
      })}
    </ul>
  );
};

export const handleDropdownClose = (dropdownRef, handleClose) => {
  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      handleClose();
    }
  };
  document.addEventListener("mousedown", handleClickOutside);
  return () => {
    document.removeEventListener("mousedown", handleClickOutside);
  };
};
