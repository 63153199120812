import React, { useState, useEffect } from "react";
import "./Header.css";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setLogOut, setProfileImage, setRefreshToken, setTokenExpTime } from "../../Redux/Action/Authaction";
import axios from "axios";
import Url from "../../Element/Api";
import proImage from "../../../assets/flat-business-man-user-profile-avatar-in-suit-vector-4333496.jpg";
import useAuth from "../../../hooks/useAuth";
import { Breadcrumbs } from "../../../utils/utils";

const Header = ({ breadcrumbData }) => {
  const [config] = useAuth();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const refreshToken = useSelector((state) => state.refreshToken);
  const notificationCount = 0;

  const handleLogOut = () => {
    dispatch(setLogOut());
    dispatch(setProfileImage(null));
    dispatch(setTokenExpTime(null));
    dispatch(setRefreshToken(null));
    navigate("/");
    axios
      .delete(`${Url.BASEURL}/admin/logout`, {
        data: {
          refreshToken: refreshToken,
        },
      })
      .then(() => {
        dispatch(setLogOut());
        dispatch(setProfileImage(null));
        dispatch(setTokenExpTime(null));
        dispatch(setRefreshToken(null));
        localStorage.clear();
        navigate("/");
      });
  };

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await axios.get(Url.BASEURL + `/admin/profile:id`, config);
        const userData = response.data;
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    // fetchUserData();
  }, []);

  return (
    <div className="header">
      <div className="left-section">{breadcrumbData && <Breadcrumbs crumbs={breadcrumbData} />}</div>

      <div className="right-section">
        {/*   <div className="search-box">
          <input type="text" placeholder="Search" />
          <span className="icon-search"></span>
        </div> */}
        <div className="bell_notification">
          <span className="icon-bell"></span>
          {notificationCount > 0 && <span className="notification_count">{notificationCount}</span>}
        </div>

        <>
          <div
            className="user_sidebar"
            data-bs-toggle="dropdown"
            aria-expanded="false"
            onClick={() => {
              setIsSidebarOpen(!isSidebarOpen);
            }}
          >
            <img src={proImage} alt="user" className="user-image" />
            <div class="dropdown roppeee">
              <ul className="dropdown-menu">
                <li className="facer">
                  <button className="sign-out-button" onClick={handleLogOut}>
                    Sign Out
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </>
      </div>
    </div>
  );
};

export default Header;
