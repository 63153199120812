import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "./TeamMates.css";
import axios from "axios";
import Url from "../Element/Api";
import useAuth from "../../hooks/useAuth";
import Sliders from "../../assets/s3.svg";
import Close from "../../assets/close.svg";
import ReactPaginate from "react-paginate";
import { ClipLoader } from "react-spinners";
import Verified from "../../assets/verified.svg";
import BaseLayout from "../../layouts/BaseLayout";
import AddRealtorModal from "./RealtorModals/AddRealtorModal";
import PendingProfileModal from "../ModalMessage/PendingProfileModal";
import { NextLabel, PreviousLabel, getInitials, handleDropdownClose } from "../../utils/utils";

const AgentdropdownOptions = ["All", "Active", "Inactive", "Pending"];

function TeamMates() {
  const [config] = useAuth();
  const { id } = useParams();
  const navigate = useNavigate();
  const dropdownRef = useRef(null);
  const [loading, setLoading] = useState(true);
  const [totalPage, setTotalPage] = useState(1);
  const [totalItem, setTotalItem] = useState(0);
  const [teamMates, setTeamMates] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [hiddenBlocks, setHiddenBlocks] = useState({});
  const [isFormVisible, setIsFormVisible] = useState(true);
  const [selectedOption, setSelectedOption] = useState("All");
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isPendingPopupVisible, setIsPendingPopupVisible] = useState(false);
  const [isInvitationSentPopupVisible, setIsInvitationSentPopupVisible] = useState(false);

  // Pagination
  const pageNumbers = [];
  const itemsPerPage = 12;
  const totalResults = totalPage;
  const totalPages = Math.ceil(totalResults / itemsPerPage);
  const currentPageAdjusted = Math.max(currentPage, 1);
  const indexOfFirstItem = (currentPageAdjusted - 1) * itemsPerPage + 1;
  const indexOfLastItem = Math.min(currentPageAdjusted * itemsPerPage, totalItem);

  for (let i = 1; i <= totalPages; i++) {
    pageNumbers.push(i);
  }

  const handleClick = (status, id) => {
    if (status === 2) {
      setIsPendingPopupVisible(true);
    } else {
      navigate(`/realtor-profile/${id}`);
    }
  };

  const toggleVisibility = (name) => {
    setHiddenBlocks((prevState) => ({
      ...prevState,
      [name]: !prevState[name],
    }));
  };

  const TeamMate = ({ id, name, imgSrc, status, onToggleVisibility }) => {
    let statusClass;
    let statusText = "";

    switch (status) {
      case 0:
        statusText = "InActive";
        statusClass = "status-actives";
        break;
      case 1:
        statusText = "Active";
        statusClass = "status-inactives";
        break;
      case 2:
        statusText = "Pending";
        statusClass = "status-pendings";
        break;
      case 3:
        statusText = "Delinquents";
        statusClass = "status-delinquents";
        break;
      default:
        statusClass = "status-unknowns";
    }

    return (
      <>
        <div role="button" className={`teammatess ${hiddenBlocks[name] ? "hidden" : ""}`} onClick={() => handleClick(status, id)}>
          {imgSrc ? <img src={imgSrc} alt={name} className="teammate-img" /> : <div className="teammate-initials">{getInitials(name)}</div>}
          <Link to={`/realtor-profile/${id}`} style={{ pointerEvents: status === 2 ? "none" : "auto" }}>
            <div className="teammate-info">
              <h3 className="text-capitalize">{name}</h3>
              <div className="two_icons d-flex">
                <div className="single_icons" onClick={onToggleVisibility}>
                  <Link className={statusClass}>{statusText}</Link>
                </div>
              </div>
            </div>
          </Link>
        </div>
      </>
    );
  };

  useEffect(() => {
    fetchRealtors();
  }, [currentPage, searchTerm]);

  const fetchRealtors = (status = "") => {
    setLoading(true);
    let url = Url.BASEURL + `/admin/realtors-under-broker/${id}`;
    // Check if status is not blank or undefined, then append to the URL
    if (status !== "" && status !== undefined) {
      url += `?status=${status}`;
    }
    // Check if page is not blank or undefined, then append to the URL
    if (currentPage !== "" && currentPage !== undefined) {
      url += url.includes("?") ? `&page=${currentPage}` : `?page=${currentPage}`;
    }
    // Check if term is not blank or undefined, then append to the URL
    if (searchTerm !== "" && searchTerm !== undefined) {
      url += url.includes("?") ? `&term=${searchTerm}` : `?term=${searchTerm}`;
    }

    axios
      .get(url, config)
      .then((value) => {
        setTeamMates(value.data.realtors);
        setTotalPage(value.data.totalPages);
        setTotalItem(value.data.totalItems);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const handleOptionSelect = (option) => {
    setSelectedOption(option);
    setIsDropdownOpen(false);

    let statusParam = "";
    if (option === "Active") {
      statusParam = "1"; // Active status
    } else if (option === "Inactive") {
      statusParam = "0"; // Inactive status
    } else if (option === "Pending") {
      statusParam = "2"; // Inactive status
    }

    fetchRealtors(statusParam);
  };

  const PostsDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  useEffect(() => {
    const handleClose = () => {
      setIsDropdownOpen(false);
    };
    handleDropdownClose(dropdownRef, handleClose);
  }, [dropdownRef]);

  const brokerId = localStorage.getItem("brokerId");
  const brokerName = localStorage.getItem("brokerName");
  const navigateFrom = localStorage.getItem("navigateFrom");

  const breadcrumbData = [{ path: navigateFrom === "dashboard" ? "/dashboard" : "/manage-broker", label: navigateFrom === "dashboard" ? "Dashboard" : "Manage Brokers" }, { path: `/profile/${brokerId}`, label: brokerName }, { label: "Realtors" }];

  return (
    <BaseLayout breadcrumbData={breadcrumbData}>
      <div className="mutual_blocks team_mamet">
        <div className="heading_inline d-flex team-matew-page">
          <div className="left_head right_head">
            <h2>Realtor</h2>
          </div>
        </div>

        <div className="row mb-3  realtor_spec_only">
          <div className="col-md-6 col-sm-12 d-flex justify-content-start align-items-center link_sett">
            <div className="lisingenumbers">{`${indexOfFirstItem}-${indexOfLastItem} of ${totalItem} Results`}</div>
          </div>
          <div className="col-md-6 col-sm-12 d-flex justify-content-end mku">
            <div className="search_area d-flex">
              <div className="search_bloxk">
                <input type="search" className="form-control me-2" placeholder="Search by Name" value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} />
              </div>
            </div>
            <div className="filter_area">
              <div className="agent_dropdown">
                <div ref={dropdownRef} className={`drop_data ${isDropdownOpen ? "open" : ""}`}>
                  <div className="selected_option" onClick={PostsDropdown} style={{ backgroundImage: `url(${Sliders})` }}>
                    {selectedOption}
                  </div>
                  {isDropdownOpen && (
                    <div className="drop_selection">
                      {AgentdropdownOptions.map((option, index) => (
                        <p key={index} onClick={() => handleOptionSelect(option)}>
                          {option}
                        </p>
                      ))}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        {loading ? (
          <div className="text-center">
            <ClipLoader color="#80C342" npm start loading={loading} size={150} aria-label="Loading Spinner" data-testid="loader" />
          </div>
        ) : (
          <>
            <div className="teammate_list d-flex team-mate-page-only">
              {teamMates.map((mate) => (
                <TeamMate id={mate._id} name={mate.firstname + " " + mate.lastname} imgSrc={mate.image ? mate.imageURL : ""} status={mate.status} onToggleVisibility={() => toggleVisibility(mate.name)} />
              ))}
            </div>

            {totalPage > 1 && (
              <div className="all_pagination">
                <ReactPaginate
                  previousLabel={<PreviousLabel />}
                  nextLabel={<NextLabel />}
                  breakLabel={"..."}
                  pageCount={totalPage}
                  marginPagesDisplayed={1}
                  pageRangeDisplayed={5}
                  onPageChange={(e) => {
                    setCurrentPage(e.selected + 1);
                  }}
                  containerClassName={"pagination"}
                  activeClassName={"active"}
                />
              </div>
            )}
          </>
        )}
        {/* Add Realtor Modal */}
        <AddRealtorModal isFormVisible={isFormVisible} onSetIsFormVisible={(value) => setIsFormVisible(value)} />
        {/* End of Add Realtor Modal */}

        {/* invite_send_popup-pending */}
        {/* Pending Popup */}

        <PendingProfileModal from="realtor" show={isPendingPopupVisible} handleClose={() => setIsPendingPopupVisible(false)} />

        <div className="popup_bhe">
          {isInvitationSentPopupVisible && <div className="modal-harddrop"></div>}

          {isInvitationSentPopupVisible && (
            <div className="email_verification verification_done add_realtor_message">
              <div className="cross_btn haroos" onClick={() => setIsInvitationSentPopupVisible(false)}>
                <img src={Close} className="close" alt="" />
              </div>
              <h3>Invitation Sent</h3>
              <p>
                You've successfully extended an invitation to{" "}
                <p>
                  <strong>John Scott.</strong>
                </p>
              </p>
              <img src={Verified} alt="verified" />
              <h4>Await their response and welcome them to your real estate network.</h4>
            </div>
          )}
        </div>
      </div>
    </BaseLayout>
  );
}
export default TeamMates;
