import React, { useState, useEffect } from "react";
import axios from "axios";
import ReactSlider from "react-slider";
import "./SetPayments.css";
import Sidebar from "../Sidebar/Sidebar";
import Header from "../Header/Header";
import Dollar from "../../../assets/dollar.svg";
import Percentage from "../../../assets/percentage.svg";
import Url from "../../Element/Api";
import useAuth from "../../../hooks/useAuth";
import ModalMessage from "../../ModalMessage/Modal";
import { ClipLoader } from "react-spinners";

function AdminSetPayments() {
  const [config] = useAuth();
  const [mlsFees, setMlsFees] = useState(0);
  const [loading, setLoading] = useState(true);
  const [marginfee, setmarginfee] = useState(0);
  const [subDomain, setSubDomain] = useState(0);
  const [perRealtor, setPerRealtor] = useState(0);
  const [mlsCharges, setMlsCharges] = useState(0);
  const [featureKey, setFeatureKey] = useState(0);
  const [isDisable, setIsDisable] = useState(false);
  const [showSaveConfirmation, setShowSaveConfirmation] = useState(false);

  const handleNumericChange = (value, setterFunction, maxValue) => {
    let numericValue = parseFloat(value) || 0;
    if (numericValue > maxValue) {
      numericValue = maxValue;
    }
    setterFunction(numericValue);
  };

  const handleSaveClick = (key) => {
    setFeatureKey(key);
    setShowSaveConfirmation(true);
  };

  const handleClose = () => {
    setShowSaveConfirmation(false);
  };

  const fetchPaymentData = () => {
    axios
      .get(Url.BASEURL + `/admin/get-payments`, config)
      .then((res) => {
        if (Array.isArray(res.data.CurrentPackagePricing)) {
          res.data.CurrentPackagePricing.forEach((element) => {
            switch (element.featureKey) {
              case 1:
                setPerRealtor(element.price);
                break;
              case 3:
                setmarginfee(element.price);
                break;
              case 2:
                setMlsFees(element.price);
                break;
              case 7:
                setMlsCharges(element.price);
                break;
              default:
            }
          });
        }

        if (res.data.mlsDocument && res.data.mlsDocument.price) {
          setMlsCharges(res.data.mlsDocument.price);
        }
      })
      .catch((err) => {
        console.log("Error fetching data:", err);
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    fetchPaymentData();
  }, []);

  // To set the payments
  const handleSave = () => {
    let params = {};
    setIsDisable(true);
    switch (featureKey) {
      case 1:
        params = { price: perRealtor, featureKey: featureKey };
        break;
      case 3:
        params = { price: marginfee, featureKey: featureKey };
        break;
      case 2:
        params = { price: mlsFees, featureKey: featureKey };
        break;
      case 7:
        params = { price: mlsCharges, featureKey: featureKey };
        break;
      default:
        params = {};
    }

    axios
      .post(Url.BASEURL + `/admin/set-payments`, params, config)
      .then((response) => {
        setShowSaveConfirmation(false);
        response.data.forEach((element) => {
          switch (element.featureKey) {
            case 1:
              setPerRealtor(element.price);
              break;
            case 3:
              setmarginfee(element.price);
              break;
            case 2:
              setMlsFees(element.price);
              break;
            case 7:
              setMlsCharges(element.price);
              break;
            default:
          }
        });
        setIsDisable(false);
      })

      .catch((error) => {
        setIsDisable(false);
        console.error("Error deleting the realtor:", error);
      });
  };

  const breadcrumbData = [{ label: "Payments" }];

  return (
    <div className="dashboard-container">
      <div className="sidebar-main">
        <Sidebar />
      </div>
      <div className="header-dasboard main_container">
        <Header breadcrumbData={breadcrumbData} />
        <div className="dashboard payment_page_only">
          <div className="container-fluid main_container">
            <div className="row">
              <div className="col-lg-12">
                <div className="mutual_blocks amp-pages set_payments">
                  <h2>Set Payments</h2>
                  <div className="margins_blocks">
                    <div className="single_margin_block">
                      <div className="margin_headings">
                        Per Wovnn Seat Price <span>$0 - $1000</span>
                      </div>
                      <div className="payment_slider">
                        <div className="left_slader">
                          <input type="text" className="form-control" id="perrealtor" value={perRealtor} onChange={(e) => handleNumericChange(e.target.value, setPerRealtor, 1000)} />
                          <div className="icon_savees">
                            <img src={Dollar} alt="" />
                          </div>
                          <ReactSlider className="horizontal-slider" thumbClassName="thumb" trackClassName="track" min={0} max={1000} step={10} value={perRealtor} onChange={(value) => setPerRealtor(value)} />
                        </div>
                        <div className="right_slade">
                          <div className="saver_btn" onClick={() => handleSaveClick(1)}>
                            Save
                          </div>
                        </div>
                      </div>
                      <div className="paymnent_texting">Set your preferred prices for broker and realtor seats using Wovnn.</div>
                    </div>
                    <div className="bdr_center"></div>

                    {/* feature key= 2 for per realtor
                    <div className="single_margin_block">
                      <div className="margin_headings">Per Realtor</div>
                      <div className="payment_slider">
                        <div className="left_slader">
                          <input
                            type="text"
                            className="form-control"
                            id="perrealtor"
                            value={perRealtor}
                            onChange={(e) =>
                              handleNumericChange(
                                e.target.value,
                                setPerRealtor
                              )
                            }
                          />
                          <div className="icon_savees">
                            <img src={Dollar} alt="" />
                          </div>
                          <ReactSlider
                            className="horizontal-slider"
                            thumbClassName="thumb"
                            trackClassName="track"
                            min={0}
                            max={1000}
                            step={5}
                            value={perRealtor}
                            onChange={(value) => setPerRealtor(value)}
                          />
                        </div>
                        <div className="right_slade">
                          <div className="saver_btn" onClick={() => handleSaveClick(2)}>
                            Save
                          </div>
                        </div>
                      </div>
                      <div className="paymnent_texting">
                        You have to set an amount for realtors adding their
                        profiles under your brokerage on the website.
                      </div>
                    </div>
                    <div className="bdr_center"></div>
                   */}

                    {/* feature key= 6 for MLS fees */}
                    <div className="single_margin_block">
                      <div className="margin_headings">
                        MLS Fees <span>$0 - $1000</span>
                      </div>
                      <div className="payment_slider">
                        <div className="left_slader">
                          <input type="text" className="form-control" id="mlsfees" value={mlsFees} onChange={(e) => handleNumericChange(e.target.value, setMlsFees, 1000)} />
                          <div className="icon_savees">
                            <img src={Dollar} alt="" />
                          </div>
                          <ReactSlider className="horizontal-slider" thumbClassName="thumb" trackClassName="track" min={0} max={1000} step={5} value={mlsFees} onChange={(value) => setMlsFees(value)} />
                        </div>
                        <div className="right_slade">
                          <div className="saver_btn" onClick={() => handleSaveClick(2)}>
                            Save
                          </div>
                        </div>
                      </div>
                      <div className="paymnent_texting">Set your initial charge at $25 for up to 4 MLS, and seamlessly add more at an additional $25 for every set of 4, ensuring flexibility and control.</div>
                    </div>
                    <div className="bdr_center"></div>
                    <div className="single_margin_block">
                      <div className="margin_headings">
                        Margin From Setup Fees <span>0% - 100%</span>
                      </div>
                      <div className="payment_slider">
                        <div className="left_slader">
                          <input type="text" className="form-control" id="marginfee" value={marginfee} onChange={(e) => handleNumericChange(e.target.value, setmarginfee, 100)} />
                          <div className="icon_savees">
                            <img src={Percentage} alt="" />
                          </div>
                          <ReactSlider className="horizontal-slider" thumbClassName="thumb" trackClassName="track" min={0} max={100} step={1} value={marginfee} onChange={(value) => setmarginfee(value)} />
                        </div>
                        <div className="right_slade">
                          <div className="saver_btn" onClick={() => handleSaveClick(3)}>
                            Save
                          </div>
                        </div>
                      </div>
                      <div className="paymnent_texting">You can set a percentage for margin on setup fees charged by brokers for adding realtor profiles.</div>
                    </div>
                    <div className="bdr_center"></div>

                    {/* feature key = 5 for Subdomain 
                    <div className="single_margin_block">
                      <div className="margin_headings">Subdomain Charges</div>
                      <div className="payment_slider">
                        <div className="left_slader">
                          <input
                            type="text"
                            className="form-control"
                            id="subdomain"
                            value={subDomain}
                            onChange={(e) =>
                              handleNumericChange(
                                e.target.value,
                                setSubDomain
                              )
                            }
                          />
                          <div className="icon_savees">
                            <img src={Dollar} alt="" />
                          </div>
                          <ReactSlider
                            className="horizontal-slider"
                            thumbClassName="thumb"
                            trackClassName="track"
                            min={0}
                            max={10000}
                            step={5}
                            value={subDomain}
                            onChange={(value) => setSubDomain(value)}
                          />
                        </div>
                        <div className="right_slade">
                          <div className="saver_btn" onClick={() => handleSaveClick(5)}>
                            Save
                          </div>
                        </div>
                      </div>
                      <div className="paymnent_texting">
                        You have to set an amount for realtors adding their
                        profiles under your brokerage on the website.
                      </div>
                    </div>
                    <div className="bdr_center"></div>

                   */}

                    {/* MLS IDX Charges  
                    <div className="single_margin_block">
                      <div className="margin_headings">MLS IDX Charges</div>
                      <div className="payment_slider">
                        <div className="left_slader">
                          <input
                            type="text"
                            className="form-control"
                            id="mlscharges"
                            value={mlsCharges}
                            onChange={(e) =>
                              handleNumericChange(
                                e.target.value,
                                setMlsCharges
                              )
                            }
                          />
                          <div className="icon_savees">
                            <img src={Dollar} alt="" />
                          </div>
                          <ReactSlider
                            className="horizontal-slider"
                            thumbClassName="thumb"
                            trackClassName="track"
                            min={0}
                            max={500}
                            step={5}
                            value={mlsCharges}
                            onChange={(value) => setMlsCharges(value)}
                          />
                        </div>
                        <div className="right_slade">
                          <div className="saver_btn" onClick={() => handleSaveClick(7)}>
                            Save
                          </div>
                        </div>
                      </div>
                      <div className="paymnent_texting">
                        You have to set an amount for realtors adding their
                        profiles under your brokerage on the website.
                      </div>
                    </div>
                          */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ModalMessage disable={isDisable} show={showSaveConfirmation} handleClose={handleClose} handleSave={handleSave} message="Do you really want to save this records? This process can be undone." leftButton="Cancel" rightButton="Save" />
    </div>
  );
}

export default AdminSetPayments;
