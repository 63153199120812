import React from "react";
import Modal from "react-bootstrap/Modal";
import Password from "../../assets/a2.svg";

const ChangePasswordModal = ({ isPasswordModalVisible, closePasswordModal }) => {
  return (
    <Modal show={isPasswordModalVisible} onHide={closePasswordModal} backdrop="static" className="milko">
      <Modal.Body>
        <div className="profile_form_entries change_pass passw_changing">
          <div className="forms_area">
            <div className="single_fields">
              <label>Current Password</label>
              <input type="text" className="form-control" placeholder="Enter current password*" />
              <div className="u_icon">
                <img src={Password} alt="user" />
              </div>
            </div>
            <div className="single_fields">
              <label>New Password</label>
              <input type="text" className="form-control" placeholder="Enter new password*" />
              <div className="u_icon">
                <img src={Password} alt="user" />
              </div>
            </div>
            <div className="single_fields">
              <label>New Password Again</label>
              <input type="text" className="form-control" placeholder="Enter new password again*" />
              <div className="u_icon">
                <img src={Password} alt="user" />
              </div>
            </div>
            <div className="btns_call d-flex">
              <button className="logout save_btn">Save</button>
              <button className="hanul" onClick={closePasswordModal}>
                Cancel
              </button>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ChangePasswordModal;
