import { Modal, Button } from "react-bootstrap";
import React from "react";
import Cross from "../../assets/close.svg";
import Shield from "../../assets/shield-green.svg";

const ModalMessage = ({ show, disable, handleClose, handleSave, message, img, leftButton, rightButton }) => {
  return (
    <Modal className="delete-modal" show={show} onHide={handleClose} centered>
      <Modal.Body>
        <div className="delete-modal-content">
          <div className="delete-modal-header">
            <img src={img ? img : Shield} alt="" />
            <h5>Are you sure?</h5>
            <img src={Cross} alt="" className="modal_deklete" onClick={handleClose} />
          </div>
          <p>{message}</p>
          <div className="delete-modal-actions">
            <Button variant="secondary" onClick={handleClose}>
              {leftButton}
            </Button>
            <Button className="green-btn" disabled={disable ? disable : false} onClick={handleSave}>
              {rightButton}
            </Button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ModalMessage;
