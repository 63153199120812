import React, { useEffect, useState } from "react";
import Sliders from "../../../assets/s3.svg";
import Closer from "../../../assets/close-grey.svg";
import axios from "axios";
import Url from "../../Element/Api";
import { useParams } from "react-router-dom";
import useAuth from "../../../hooks/useAuth";
import { ClipLoader } from "react-spinners";
import RealtorTransactionData from "./RealtorTransactionData";
import RealtorTransactionModalData from "./RealtorTransactionModalData";

const RealtordownOptions = [
  { type: "heading", content: "Payment" },
  { type: "option", content: "All", value: " " },
  { type: "option", content: "Successful", value: "succeeded" },
  { type: "option", content: "Failed", value: "failed" },
];

const RealtorTransactions = ({ onSetSelectedOption }) => {
  const { id } = useParams();
  const [config] = useAuth();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedOptionRealtor, setSelectedOptionRealtor] = useState(" ");
  const [bgTransactions, setBgTransactions] = useState([]);
  const [bgTransactionsLoading, setBgTransactionsLoading] = useState([]);
  const [transactions, setTransactions] = useState([]);
  const [transactionLoading, setTransactionLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const nextPage = () => {
    setCurrentPage((prev) => Math.min(prev + 1, totalPages));
  };

  const prevPage = () => {
    setCurrentPage((prev) => Math.max(prev - 1, 1));
  };

  const handleClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleOptionSelect = (option) => {
    setSelectedOptionRealtor(option);
    onSetSelectedOption(option);
    setIsDropdownOpen(false);
  };

  useEffect(() => {
    const fetchTransactions = async () => {
      try {
        setTransactionLoading(true);
        const queryValue = RealtordownOptions.find((item) => item.content === selectedOptionRealtor)?.value || "";
        const response = await axios.get(Url.BASEURL + `/admin/get-transactions-of-realtor-under-broker/${id}?page=${currentPage}&status=${queryValue}`, config); // Adjust endpoint URL
        if (response.data.success) {
          setTransactions(response?.data?.allTransactions);
          setCurrentPage(response?.data?.currentPage);
          setTotalPages(response?.data?.totalPages);
          setTransactionLoading(false);
        } else {
          setTransactionLoading(false);
        }
      } catch (error) {
        setTransactionLoading(false);
      } finally {
        setTransactionLoading(false);
      }
    };

    fetchTransactions();
  }, [currentPage, selectedOptionRealtor]);

  useEffect(() => {
    const fetchTransactions = async () => {
      try {
        setBgTransactionsLoading(true);
        const queryValue = RealtordownOptions.find((item) => item.content === selectedOptionRealtor)?.value || "";
        const response = await axios.get(Url.BASEURL + `/admin/get-transactions-of-realtor-under-broker/${id}?page=${currentPage}&status=${queryValue}`, config); // Adjust endpoint URL
        if (response?.data?.success) {
          setBgTransactions(response?.data?.allTransactions);
          setBgTransactionsLoading(false);
        } else {
          setBgTransactionsLoading(false);
        }
      } catch (error) {
        setBgTransactionsLoading(false);
      } finally {
        setBgTransactionsLoading(false);
      }
    };

    fetchTransactions();
  }, []);

  const RealtorTransactionModalOldData = ({ transaction }) => {
    return (
      <tr>
        <td>
          MLS <span className="blacking">{transaction.selectedMlsCount}</span> :<span className="blacking"> ${transaction.selectedMlsPrice}</span> + Wovnn Fees : <span className="blacking">${transaction.wovnnFees}</span>
          <br />
          {transaction.realtorName ? (
            <div>
              Sub Domain : <span className="blacking">${transaction.subdomainData}</span>
            </div>
          ) : null}
          {transaction.realtorName ? (
            <div>
              + Margin :{" "}
              <span className="blacking">
                ${transaction.brokerMarginAmount} ({transaction.brokerMarginPercentage}%)
              </span>
            </div>
          ) : (
            <div>
              Realtor Seats :{" "}
              <span className="blacking">
                {transaction.realtorSeats} : ({transaction.realtorFees})
              </span>
            </div>
          )}
          {transaction.brokerSetUpFee ? (
            <div>
              + Set Up Fees (set by broker) : <span className="blacking">${transaction.brokerSetUpFee}</span>
            </div>
          ) : null}
        </td>
        <td>
          {transaction.realtorName ? (
            <>
              <div className="blacking">
                <span className="blacking">${transaction.amount}</span>
              </div>
              <div className="blacking">{transaction.realtorName}</div>
            </>
          ) : (
            <div className="blacking">-</div>
          )}
        </td>
        <td>
          <div className="blacking">
            <span className="blacking">${transaction.brokerFinalAmount}</span>
          </div>
          <div className="blacking">{transaction.brokerName}</div>
        </td>

        <td>
          <div className="status">${transaction.wovnnCut}</div>
          <div className="success super_class">{transaction.status === "succeeded" ? "Successful" : "Failed"}: </div>
          <div className="date_set super_class">
            {new Date(transaction.date).toLocaleDateString("en-US", {
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
            })}
          </div>
        </td>
      </tr>
    );
  };

  const RealtorTransactionsModal = () => {
    return (
      <div className="modal fade realtor_modal all_transactionsal" id="addRealtorTransactionModal" tabIndex="-1" aria-labelledby="addRealtorTransactionModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-body custo_real-modal">
              <div className="modal_closer" data-bs-dismiss="modal" aria-label="Close">
                <img src={Closer} alt="" />
              </div>

              <div className="full_rel_trans d-flex">
                <div className="left_real_heading">Realtor Transaction</div>
                <div className="right_real_dropdown">
                  <div className="filter_area">
                    <div className="agent_dropdown">
                      <div className={`drop_data ${isDropdownOpen ? "open" : ""}`}>
                        <div
                          className="selected_option"
                          onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                          style={{
                            backgroundImage: `url(${Sliders})`,
                          }}
                        >
                          Filters
                        </div>
                        {isDropdownOpen && (
                          <div className="drop_selection">
                            {RealtordownOptions.map((item, index) => {
                              if (item.type === "heading") {
                                return <h3 key={index}>{item.content}</h3>;
                              } else {
                                return (
                                  <p key={index} onClick={() => handleOptionSelect(item.content)}>
                                    {item.content}
                                  </p>
                                );
                              }
                            })}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="payment_type_status all_realtor_payment_transactions">
                <div className="table-responsive">
                  {transactionLoading ? (
                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "50vh" }}>
                      <ClipLoader color="#80C342" loading={transactionLoading} size={150} aria-label="Loading Spinner" data-testid="loader" />
                    </div>
                  ) : (
                    <table>
                      <thead>
                        <tr>
                          <th>Payment Type</th>
                          <th>Realtor</th>
                          <th>Broker</th>
                          <th>Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        {transactions.length === 0 ? (
                          <tr className="text-center">
                            <td
                              colSpan="9"
                              style={{
                                fontSize: 16,
                                marginTop: "10px",
                                alignSelf: "center",
                                textAlign: "center",
                              }}
                            >
                              No Transactions Found
                            </td>
                          </tr>
                        ) : (
                          transactions.map((transaction, index) => <RealtorTransactionModalData key={index} transaction={transaction} />)
                        )}
                      </tbody>
                    </table>
                  )}
                </div>

                {totalPages > 1 && (
                  <div className="custom-pagination">
                    <ul>
                      <li className="left_page" onClick={prevPage}>
                        Prev
                      </li>
                      {Array.from({ length: totalPages }, (_, i) => i + 1).map((number) => (
                        <li key={number} onClick={() => handleClick(number)} className={currentPage === number ? "active-page" : ""}>
                          {number}
                        </li>
                      ))}
                      <li className="right_page" onClick={nextPage}>
                        Next
                      </li>
                    </ul>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const RealtorTransactionOldData = ({ transaction }) => {
    return (
      <tr>
        <td>
          MLS <span className="blacking">{transaction.selectedMlsCount}</span> :<span className="blacking"> ${transaction.selectedMlsPrice}</span> + Wovnn Fees : <span className="blacking">${transaction.wovnnFees}</span>
          <br />
          {transaction.realtorName ? (
            <div>
              Sub Domain : <span className="blacking">${transaction.subdomainData}</span>
            </div>
          ) : null}
          {transaction.realtorName ? (
            <div>
              + Margin :{" "}
              <span className="blacking">
                ${transaction.brokerMarginAmount} ({transaction.brokerMarginPercentage}%)
              </span>
            </div>
          ) : (
            <div>
              Realtor Seats :{" "}
              <span className="blacking">
                {transaction.realtorSeats} : ({transaction.realtorFees})
              </span>
            </div>
          )}
          {transaction.brokerSetUpFee ? (
            <div>
              + Set Up Fees (set by broker) : <span className="blacking">${transaction.brokerSetUpFee}</span>
            </div>
          ) : null}
        </td>
        <td>
          <div className="status">${transaction.wovnnCut}</div>
          <br />
          <div className="success super_class">{transaction.status === "succeeded" ? "Successful" : "Failed"}: </div>
          <div className="date_set super_class">
            {new Date(transaction.date).toLocaleDateString("en-US", {
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
            })}
          </div>
        </td>
      </tr>
    );
  };

  return (
    <div className="col-lg-5 col-md-12 col-sm-12">
      <div className="mutual_blocks ">
        <div className="heading_inline d-flex">
          <div className="left_head">
            <h2>Realtor Transaction</h2>
          </div>
          <div className="right_head">
            <div className="details-link" data-bs-toggle="modal" data-bs-target="#addRealtorTransactionModal">
              View all
            </div>
            {/* The Modal */}
            <RealtorTransactionsModal />
          </div>
        </div>

        {bgTransactionsLoading ? (
          <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "50vh" }}>
            <ClipLoader color="#80C342" loading={bgTransactionsLoading} size={150} aria-label="Loading Spinner" data-testid="loader" />
          </div>
        ) : (
          <div className="payment_type_status">
            <div className="table-responsive">
              <table>
                <thead>
                  <tr>
                    <th>Payment Type</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {bgTransactions.length === 0 ? (
                    <tr className="text-center">
                      <td
                        colSpan="9"
                        style={{
                          fontSize: 16,
                          marginTop: "10px",
                          alignSelf: "center",
                          textAlign: "center",
                        }}
                      >
                        No Transactions Found
                      </td>
                    </tr>
                  ) : (
                    bgTransactions.slice(0, 3).map((transaction, index) => <RealtorTransactionData key={index} transaction={transaction} />)
                  )}
                </tbody>
              </table>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default RealtorTransactions;
